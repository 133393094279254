import React, { useState, useEffect } from "react";
import "../../../../../styles.scss";
import "../../../../settings/settings.scss";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteUserById,
  getUserById,
  updateUser,
} from "../../../../../api/api";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { PropertiesForm } from "../../../../../components/propertiesForm";
import { GroupsForm } from "../../../../../components/groupsForm";
import CircularProgress from "@mui/material/CircularProgress";
import AlertDialog from "../../../../../components/dialog/AlertDialog";

const EditUser = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const status = useSelector((state) => state.sidebar.value);
  const listGroups = useSelector((state) => state.state.groupList);
  const listProperties = useSelector((state) => state.state.propertiesList);

  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [groupChecked, setGroupChecked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loader, setLoader] = useState(true);
  const [showDialoge,setShowDialouge] = useState(false)

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    checkPassword: "",
    title: "",
    isAdmin: false,
    properties: [],
    propertyGroups: [],
  });

  const [hasErrors, setHasErrors] = useState({
    firstName: true,
    lastName: true,
    email: true,
    password: true,
    checkPassword: true,
    title: true,
    isAdmin: true,
  });
  // Handle click event of admin button
  const handleRedirectAdminButton = () => navigate("/admin");

  // Handle input change of user form
  const handleInputChange = (event) => {
    console.log(event.target)
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
    setHasErrors({
      ...hasErrors,
      [event.target.name]: false,
    });
  };

  const handleSave = () => {
    let errors = { ...hasErrors };
    let hasError = false;

    Object.keys(user).forEach((key) => {
      // if (!user[key]) {
      if (!user) {
        errors[key] = true;
        hasError = true;
      }
    });

    if (user.password !== "") {
      if (user.password !== user.checkPassword) {
        errors.password = true;
        errors.checkPassword = true;
        hasError = true;
      }
    } else {
      hasError = false;
    }

    setHasErrors(errors);

    if (hasError) {
      console.log("Hay errores en el formulario");
      return;
    }

    const filterList = listProperties.filter((item) => item.value === 1);
    const filterGroups = listGroups.filter((item) => item.value === 1);

    if (filterList.length === 0 && filterGroups.length === 0) {
      hasError = true;
    } else {
      hasError = false;
    }

    setHasErrors(errors);

    if (hasError) {
      console.log("Hay errores en el formulario");
      return;
    }

    setUser({ ...user, properties: filterList, propertyGroups: filterGroups });

    console.log("El formulario es válido, se puede enviar");

    fetchedUpdateUserInfo(filterList, filterGroups);
  };

  const handleTableChange = (event) => {
    let role = event.target.value === "admin";
    setUser({
      ...user,
      isAdmin: role,
    });
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const fetchedUpdateUserInfo = async (properties, propertiesGroup) => {
    try {
      let request = user;
      request.propertyGroups = propertiesGroup;
      request.properties = properties;
      console.log(request);
      await updateUser(userId, request);
      setError(false);
      setAlertText("User Updated successfully");
      setShow(true);
      let delayredirect = setTimeout(()=>{
        handleRedirectAdminButton();
        clearTimeout(delayredirect)
      },600)
    } catch (e) {
      console.log(e.response.data.msg);
      setError(true);
      setAlertText(e.response.data.msg || "Server error");
      setShow(true);
    }
  };

  const fetchedGetUserInfo = async () => {
    try {
      const userInfo = await getUserById(userId);
      setUser({
        ...user,
        firstName: userInfo?.user?.firstName,
        lastName: userInfo?.user?.lastName,
        email: userInfo?.user?.email,
        title: userInfo?.user?.title,
        isAdmin: userInfo?.user?.isAdmin,
        properties: userInfo?.user?.properties,
        propertyGroups: userInfo?.user?.propertyGroups,
      });

      setHasErrors({
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        checkPassword: false,
        title: false,
        isAdmin: false,
      });

      setLoader(false);
    } catch (e) {
      console.log(e);
    }
  };

  const confirmDelete = async ()=>{
      try {
        await deleteUserById(userId).then((res) => {
          setError(false);
          setAlertText("User deleted successfully");
          setShow(true);
          setTimeout(()=>{
            handleRedirectAdminButton();
          },500)
        });
      } catch (e) {
        setError(true);
        setAlertText("User deleted error, try later");
        setShow(true);
      }
  }

  const handleDelete = async (event) => {
setShowDialouge((prevState => !prevState))
if(event === true){
  await confirmDelete()
  setShowDialouge(false)
}
if(!event){
setShowDialouge(false)
}
  };

  useEffect(() => {
    fetchedGetUserInfo();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  return (
    <div id="main">
      <div className={`content ${status}`}>
        <div className="settings-container">
          <Snackbar
            onClose={handleClose}
            open={show}
            sx={{ height: "100%" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert
              onClose={handleClose}
              severity={error ? "error" : "success"}
              sx={{ width: "100%" }}
            >
              {alertText}
            </Alert>
          </Snackbar>
          {showDialoge && <AlertDialog confirmButton={handleDelete}>Are you sure you want to delete this user?</AlertDialog>}
          <div>
            <h2>{user?.firstName + " " + user?.lastName}</h2>
            <div className="line"></div>
          </div>
          <div className="content-mid">
            <div>
              <div style={{ margin: "2rem 2rem 0 2rem" }}>
                <h3 style={{ marginBottom: "1rem" }}>User Information</h3>
                <div>
                  Role
                  <div onChange={handleTableChange} className="radios">
                    <div>
                      <input
                        type="radio"
                        value="user"
                        name="isAdmin"
                        id="blue"
                        checked={!user.isAdmin}
                      />
                      User
                    </div>
                    <div style={{ marginLeft: "2rem" }}>
                      <input
                        type="radio"
                        value="admin"
                        name="isAdmin"
                        id="blue"
                        checked={user.isAdmin}
                      />
                      Admin
                    </div>
                  </div>
                </div>
              </div>
              <div className="settings-middle">
                <label>First Name</label>
                <input
                  className={hasErrors.firstName ? "error" : ""}
                  value={user.firstName}
                  name="firstName"
                  type="text"
                  onChange={handleInputChange}
                ></input>
                <label>Last Name</label>
                <input
                  className={hasErrors.lastName ? "error" : ""}
                  value={user.lastName}
                  name="lastName"
                  type="text"
                  onChange={handleInputChange}
                ></input>
                <label>Email</label>
                <input
                  className={hasErrors.email ? "error" : ""}
                  value={user.email}
                  name="email"
                  type="text"
                  onChange={handleInputChange}
                ></input>
                <label>Password</label>
                <input
                autoComplete="off"
                  className={hasErrors.password ? "error" : ""}
                  value={user.password}
                  name="password"
                  type="password"
                  onChange={handleInputChange}
                ></input>
                <label>Confirm Password</label>
                <input
                  className={hasErrors.checkPassword ? "error" : ""}
                  value={user.checkPassword}
                  name="checkPassword"
                  type="password"
                  onChange={handleInputChange}
                ></input>
                <label>Title</label>
                <input
                  className={hasErrors.title ? "error" : ""}
                  value={user.title}
                  name="title"
                  type="text"
                  onChange={handleInputChange}
                ></input>
              </div>
            </div>
            {/* <div className="rightMargin"> */}
            <div
              // style={{
              //   display: "flex",
              //   justifyContent: "space-between",
              //   alignItems: "center",
              // }}
              style={{ margin: "2rem 2rem 0 2rem" }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center", marginTop: '-3%' }}>
                <h3>Property Groups</h3>
                <button
                  style={{ alignSelf: 'flex-start' }}
                  onClick={() => setGroupChecked(!groupChecked)}
                >
                  {!groupChecked ? 'Check' : 'Uncheck'} All
                </button>
              </div>

              {loader ? (
                <div
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "center",
                  //   margin: "3rem 0",
                  // }}
                  style={{ margin: "2rem 2rem 0 2rem" }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <GroupsForm selected={user?.propertyGroups} checkAll={groupChecked ? 1 : 2} edit={true} />
              )}
            </div>
            <div
              // style={{
              //   display: "flex",
              //   justifyContent: "space-between",
              //   alignItems: "center",
              //   marginTop: "1.5rem",
              // }}
              style={{ margin: "2rem 2rem 0 2rem" }}
            >
              {/* <h3>Properties</h3>
                <button onClick={() => setChecked(!checked)}>
                  {!checked ? "Check" : "Uncheck"} All
                </button> */}
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center", marginTop: '-3%' }}>
                <h3>Properties</h3>
                <button
                  style={{ alignSelf: 'flex-start' }}
                  onClick={() => setChecked(!checked)}
                >
                  {!checked ? 'Check' : 'Uncheck'} All
                </button>
              </div>

              {loader ? (
                <div
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "center",
                  //   margin: "3rem 0",
                  // }}
                  style={{ margin: "2rem 2rem 0 2rem" }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <PropertiesForm
                  checkAll={checked ? 1 : 2}
                  selected={user?.properties}
                  edit={true}
                />
              )}
            </div>
            {/* </div> */}
          </div>
          <div style={{ margin: "0 2rem 0 2rem" }}>
            <br></br>
          </div>
          <div className="settings-bottom">
            <div>
              <button className="gray" onClick={handleRedirectAdminButton}>
                CANCEL
              </button>
              <button
                className="red"
                style={{ marginLeft: "15px" }}
                onClick={handleDelete}
              >
                DELETE
              </button>
            </div>
            <div>
              <button className="blue" onClick={handleSave}>
                SAVE CHANGES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
