import React from "react";
import ReactSpeedometer from "react-d3-speedometer";

const styles = {
  dial: {
    display: "flex",
    width: `100%`,
    height: `auto`,
    color: "#000",
    border: "0.5px solid #fff",
    justifyContent: "center",
  },
  title: {
    fontSize: "1em",
    color: "#000"
  }
};

const SpeedChart = ({ value, maxValue = 0  }) => {
  return (
     <div style={styles.dial}>
      <ReactSpeedometer
        maxValue={parseInt(maxValue / 0.75)}
        minValue={0}
        height={190}
        width={250}
        value={value>(maxValue/.75)?(maxValue/.75)+1:value}
        valueFormat={'d'}
        needleColor="#0199AD"
        segmentColors={["#BA0101", "#BA0101", "#BA0101", "#BA0101", "#32821E"]}
        segments={5}
        ringWidth={30}
        forceRender={true}
        currentValueText={value>(maxValue/.75)?`${parseInt(maxValue/.75)}+ MTD`:`${parseInt(value)} MTD`}
      />
    </div>
  );
};

export default SpeedChart;