import React,{useEffect, useRef, useState} from "react";
import useTableHeight from "../../../../hooks/useTableHeight";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";

const WorkOrders = ({ data }) => {
  
  const height = useTableHeight(data)
  
  const [pageWidth, setPageWidth] = useState(null)
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  let functionDelaytimeOut

  window.addEventListener('resize', (size) => {
    let currentTarget = size?.currentTarget?.innerWidth
    if (functionDelaytimeOut) {
      clearTimeout(functionDelaytimeOut);
    }
    functionDelaytimeOut = setTimeout(() => {
      setPageWidth(currentTarget);
    }, 800);
  });

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
    return () => {
      if (functionDelaytimeOut) {
        clearTimeout(functionDelaytimeOut);
      }
    };
  }, [pageWidth]);

  const vwToPx = (vw) => (vw * width) / 100;
  
  const columnNames = [
    { name: "Property", type: "singleSelect", width:vwToPx(21)},
    { name: "Daily Avg", type: "number", width:vwToPx(13) },
    { name: `${data[0].week0.name}`, type: "number", width:vwToPx(13) },
    { name: `${data[0].week1.name}`, type: "number", width:vwToPx(13) },
    { name: `${data[0].week2.name}`, type: "number", width:vwToPx(13) },
    { name: `${data[0].week3.name}`, type:'number', width:vwToPx(13)},
    { name: `${data[0].week4.name}`, type:'number', width:vwToPx(13)},
  ];

  data = data.map((val, index)=>{
return {
  id:index,
  propertyID:val.propertyID,
  name:val.name,
  avg:val.avg,
  week0Data:val.week0.data,
  week1Data:val.week1.data,
  week2Data:val.week2.data,
  week3Data:val.week3.data,
  week4Data:val.week4.data,
}
  })

  const columnGroupingModel = [
    {
      groupId: '4 Weeks',
      headerName: `4 Weeks ago`,
      headerAlign: 'center',
      children: [{ field: 'week0Data' }],
    },
    {
      groupId: '3 weeks',
      headerName: `3 weeks ago`,
      headerAlign: 'center',
      children: [{ field: 'week1Data' }],
    },
    {
      groupId: '2 weeks',
      headerName: `2 weeks ago`,
      headerAlign: 'center',
      children: [{ field: 'week2Data' }],
    },
    {
      groupId: 'last Week',
      headerName: `Last Week`,
      headerAlign: 'center',
      children: [{ field: 'week3Data' }],
    },
    {
      groupId: 'this week',
      headerName: `This Week`,
      headerAlign: 'center',
      children: [{ field: 'week4Data' }],
    },
  ];

  let { columenGen } = useMUIFilter(
    data,
    columnNames,
    "New Work Order Trend"
  );

  let columns = columenGen();


  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);


  return (
    <div className="container-table" style={{ height: height }} ref={elementRef}>
      <DataGridPremium
  className="grid-style"
  rows={data}
  columns={columns}
  pinnedColumns={{left: ['name']}}
  slots={{ toolbar: ExportToolbar}}
  disableColumnMenu={true}
  columnGroupingModel={columnGroupingModel}
  experimentalFeatures={{ columnGrouping: true }}
  disableVirtualization
  hideFooter
  disableColumnSelector
  initialState={{
    aggregation: {
      model: {
        avg: 'sum',
        week0Data:'sum',
        week1Data: 'sum',
        week2Data:'sum',
        week3Data:'sum',
        week4Data:'sum',
      },
    },
  }}
/>
    </div>
  );
};

export default WorkOrders;

//Lagecy code
// import React from "react";
// import useTableHeight from "../../../../hooks/useTableHeight";

// const WorkOrders = ({ data }) => {
//   const height = useTableHeight(data)

//   const totalavg = data.reduce((acc, curr) => acc + curr?.avg, 0);
//   const totald1 = data.reduce((acc, curr) => acc + curr?.week0.data, 0);
//   const totald2 = data.reduce((acc, curr) => acc + curr?.week1.data, 0);
//   const totald3 = data.reduce((acc, curr) => acc + curr?.week2.data, 0);
//   const totald4 = data.reduce((acc, curr) => acc + curr?.week3.data, 0);

//   return (
//     <div className="container-table" style={{ height: height }}>
//       <div className="wrapper">
//         <table>
//           <thead>
//             <tr>
//               <th className="subparent">Property</th>
//               <th>Daily Avg</th>
//               <th className="subparent">this week</th>
//               <th className="subparent">last week</th>
//               <th className="subparent">2 weeks ago</th>
//               <th className="subparent">3 weeks ago</th>
//               <th className="subparent">4 weeks ago</th>
//             </tr>
//             <tr className="subchild">
//               <th></th>
//               <th></th>
//               <th>{data[0]?.week0.name}</th>
//               <th>{data[0]?.week1.name}</th>
//               <th>{data[0]?.week2.name}</th>
//               <th>{data[0]?.week3.name}</th>
//               <th>{data[0]?.week4.name}</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td>{item?.name}</td>
//                 <td>{item?.avg}</td>
//                 <td>{item?.week0.data}</td>
//                 <td>{item?.week1.data}</td>
//                 <td>{item?.week2.data}</td>
//                 <td>{item?.week3.data}</td>
//                 <td>{item?.week4.data}</td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td></td>
//               <td>{totalavg}</td>
//               <td>{totald1}</td>
//               <td>{totald2}</td>
//               <td>{totald3}</td>
//               <td>{totald4}</td>
//               <td>{totald4}</td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default WorkOrders;

