import React, { useEffect, useState } from 'react';
import LineChartTD from '../chart';

const Tabs = ({ data }) => {
  const [tabs, setTabs] = useState([]);
  const [labels, setLabels] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    generateTabs(data);
    generateLabels();
    generateDatasets(data);
  }, [data]);

  const generateTabs = (data) => {
    const newTabs = data.map((property) => {
      return {
        label: property.name,
        variant: property.propertyID,
        datasets: generateDatasets(property.result)
      };
    });

    setTabs(newTabs);
    setSelectedTab(newTabs[0]); // Seleccionar la primera propiedad por defecto
  };

  const generateDatasets = (property) => {
    const newDatasets = property?.map((month) => {
      return {
        label: month.name,
        data: generateData(month.data)
      };
    });
    return newDatasets;
  };

  const generateLabels = () => {
    let labelArray = [];
    for (let i = 0; i <= 30; i++) {
      labelArray.push(i + 1);
    }
    setLabels(labelArray);
  };

  const generateData = (data) => {
    let dataArray = data?.map(d => d.value);
    return dataArray;
  };

  const handleTabChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedTab = tabs[selectedIndex];
    setSelectedTab(selectedTab);
  };

  return (
    <div>
      <select className="tabs-menu" onChange={handleTabChange} value={selectedTab?.variant} style={{cursor:'pointer'}}>
        {tabs.map((tab, index) => (
          <option key={index} value={tab.variant}>{tab.label}</option>
        ))}
      </select>
      <div className="chart-container">
        {selectedTab && <LineChartTD dataChart={selectedTab?.datasets} labels={labels} />}
      </div>
    </div>
  );
};

export default Tabs;
