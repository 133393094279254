import React, { useState, useEffect } from "react";
import "../../styles.scss";
import "./overview.scss";
import { useSelector } from "react-redux";
import SpeedChart from "./components/speedChart/speedChart";
import RecentNotes from "./components/recentNotes";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  getApplications,
  getCurrentD,
  getLastUpdate,
  getMoveInMoveOut,
  getWorkOrders,
  getRecentNotes
} from "../../api/api";
import useFilter from "../../hooks/useFilter";
import useFilterOverview from "../../hooks/useFilterOverview";
import CircularProgress from "@mui/material/CircularProgress";
import { money } from "../../functions/decimals";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import useFilterResponse from "../../hooks/useFilterResponse";

const Overview = () => {
  const navigate = useNavigate();
  const status = useSelector((state) => state.sidebar.value);
  const today = new Date();
  const formattedToday = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(today);
  const { handleToggleFilter } = useFilter();
  const {
    loader: applicationLoader,
    sumParam: mtd,
    sumParam2: goals,
  } = useFilterOverview(getApplications, "applicationOverView");

  const {
    loader: workOrdersLoader,
    sumParam: currentWO,
    sumParam2: openWO,
    sumParam3: lasCurrent,
  } = useFilterOverview(getWorkOrders, "currentWorkOrderOverview");

  const {
    loader: currentDLoader,
    sumParam: currentD,
    sumParam2: totalM,
  } = useFilterOverview(getCurrentD, "currentDelinquencyOverView");

  const {
    loader: movesLoader,
    sumParam: moveIn,
    sumParam2: moveOn,
  } = useFilterOverview(getMoveInMoveOut, "scheduleMovInMovOut");

  const {
    response: recentNotesData,
    loader: recentNotesLoader,
    fetchData: reftechRecentNotes,
  } = useFilterResponse(getRecentNotes, "recentNotes");

  const [lastUpdate, setLastUpdate] = useState("");

  useEffect(() => {
    const lastUpdateFetch = async () => {
      const fetchLastUpdated = await getLastUpdate();
      const inputDate = fetchLastUpdated.lastUpdate;

      // Parse the input date string into a Date object
      const date = new Date(inputDate);

      // Define the options for formatting the date
      const options = {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      // Format the date using Intl.DateTimeFormat
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        date
      );
      setLastUpdate(`${formattedDate}  EST`);
    };

    lastUpdateFetch();
  }, []);

  useEffect(() => {
    reftechRecentNotes();
  }, [localStorage.getItem("@properties-filter")]);

  const handleRedirectMaintenanceButton = () => navigate("/collections");
  const handleRedirectLeasingButton = () => navigate("/leasing");
  const handleRedirectNotesButton = () => navigate("/notesDetails");

  return (
    <>
      <div id="main">
        <div className="header">
          <div className={`title ${"left-" + status}`}>
            {lastUpdate !== null ? (
              <div className="left-child">
                <div className="date-content">
                  <h2 style={{ color: "#0A517E" }}>
                    {formattedToday}&nbsp;{today.getFullYear()}
                  </h2>
                </div>
                <div className="date-content">
                  <h2 style={{ color: "#0A517E" }}>Updated as of </h2>
                  <h2 style={{ color: "#A2791E", marginLeft: "0.5rem" }}>
                    {lastUpdate?.split(" ")[0]} {lastUpdate?.split(" ")[1]}
                  </h2>
                  <h2 style={{ color: "#0A517E", marginLeft: "0.5rem" }}>at</h2>
                  <h2 style={{ color: "#0199AD", marginLeft: "0.5rem" }}>
                    {lastUpdate?.split(" ")[2]}&nbsp;
                    {lastUpdate?.split(" ")[3]}&nbsp;
                    {lastUpdate?.split(" ")[4]}&nbsp;
                    {lastUpdate?.split(" ")[5]}&nbsp;
                  </h2>
                </div>
              </div>
            ) : (
              <></>
            )}
            <button onClick={handleToggleFilter} className="blue">
              PROPERTIES FILTERS <FilterAltIcon sx={{ fontSize: "12px" }} />
            </button>
          </div>
        </div>
        <div className={`content ${status}`}>
          <div className="overview-container">
            <div className="child">
              <h2>Collections</h2>
              <div className="line"></div>
              <div className="remaining">
                <h1>{currentDLoader ? 0 : money(totalM)}</h1>
                <p>
                  Remaining Balance:{" "}
                  <label>{currentDLoader ? 0 : money(currentD)}</label>
                </p>
              </div>
              <div className="child-button">
                <button onClick={handleRedirectMaintenanceButton}>
                  SEE COLLECTIONS
                </button>
              </div>
            </div>
            <div className="child">
              <h2>Scheduled Move Ins/Outs</h2>
              <div className="line"></div>
              <div className="moves">
                <div className="child-move">
                  <p>In</p>
                  <Link smooth to={"/occupancy#ScheduledMoveIns&MoveOut"}>
                    <h1 className="blue">{movesLoader ? 0 : moveIn}</h1>
                  </Link>
                </div>
                <div className="child-move">
                  <p>Out</p>
                  <Link smooth to={"/occupancy#ScheduledMoveIns&MoveOut"}>
                    <h1 className="red">{movesLoader ? 0 : moveOn}</h1>
                  </Link>
                </div>
              </div>
            </div>
            <div className="child">
              <h2>Completed Work Orders</h2>
              <div className="line"></div>
              <div className="moves">
                <Link smooth to={"/maintenance/#NewWorkOrderTrend"}>
                  <div className="child-move">
                    <p>Current month</p>
                    <div className="remark">
                      <h1 style={{ fontWeight: 600 }}>
                        {workOrdersLoader ? 0 : currentWO}
                      </h1>
                    </div>
                  </div>
                </Link>
                <Link smooth to={"/maintenance/#NewWorkOrderTrend"}>
                  <div className="child-move">
                    <p>Past month</p>
                    <div className="remark">
                      <h1 style={{ fontWeight: 600 }}>
                        {workOrdersLoader ? 0 : lasCurrent}
                      </h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="child">
              <h2>Open Work Orders</h2>
              <div className="line"></div>
              <div className="moves">
                <div className="child-move">
                  <p></p>
                  <div className="remark">
                    <Link to={"/maintenance-detail"}>
                      <h1 style={{ fontWeight: 600 }}>
                        {workOrdersLoader ? 0 : openWO}
                      </h1>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="child">
              <h2>Applications</h2>
              <div className="line"></div>
              {applicationLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <SpeedChart value={mtd} maxValue={goals} />
              )}
              <Link smooth to={"/leasing/#LeasingSuccess"}>
                <div className="child-button" style={{ margin: "0 1rem" }}>
                  <button onClick={handleRedirectLeasingButton}>
                    SEE APPLICATIONS
                  </button>
                </div>
              </Link>
            </div>
            <div className="child __minWidth">
              <h2>Recent Notes</h2>
              <div className="line"></div>
              {recentNotesLoader ? (<CircularProgress/>) : (recentNotesData.length > 0 ? <RecentNotes data={recentNotesData} /> : <h2 style={{height:'66%'}}><br></br> No New Notes<br></br> in the last 7 days </h2>)}
                <div className="child-button" style={{ margin: "0 1rem", marginTop:'2%' }}>
                  <button onClick={handleRedirectNotesButton}>
                    See All Notes Activity
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
