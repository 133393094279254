import React,{useEffect, useState} from "react";
import { useSelector } from "react-redux";
import LeasingSucessMore from "../../tables/leasingSucessMore";
import { useNavigate } from "react-router-dom";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getLeadConversion } from "../../../../api/api";
import CircularProgress from "@mui/material/CircularProgress";
import FilterButton from "../../../../components/filterButton";
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import dayjs from "dayjs";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { getFirstAndCurrentDayOfCurrentMonth,getDefaultDates } from "../../../../functions/dates/getFirstAndCurrentDayOfCurrentMonth";

const LeadConversion = () => {
  const navigate = useNavigate();
  const status = useSelector((state) => state.sidebar.value);
  const handleRedirectLeasingButton = () => navigate("/leasing");
  const [source, setSource] = useState('Lease Source')
  // const {firstDayOfMonth:firstDay, todayDate:lastDay} = getFirstAndCurrentDayOfCurrentMonth()
  const {defaultStartDate:firstDay, defaultEndDate:lastDay} = getDefaultDates()
  const [selectDate, setSelectDate] = useState({startDate:firstDay, endDate:lastDay})
  const { response, loader, fetchData, setLoader } = useFilterResponse(getLeadConversion, `successDetail`, `source=${source}&start=${selectDate?.startDate}&end=${selectDate?.endDate}`); // sending default params as object

  function sourceToggler(val){
    setLoader((prevState) => !prevState)
    if(val.target.checked){
      //  fetchData("source=Leasing Agent")
       setSource("Leasing Agent")
    }else{
      // fetchData("source=Lease Source")
      setSource("Lease Source")
    }
  }

function datePickerHandler(val){
    let startDate = new Date(val[0]?.$d).toISOString()
    let endDate = new Date(val[1]?.$d).toISOString()
    setSelectDate({startDate,endDate})
}

useEffect(()=>{
  if(firstDay !== selectDate.startDate && lastDay !== selectDate.endDate){
    fetchData(`source=${source}&start=${selectDate?.startDate}&end=${selectDate?.endDate}`)
  }
},[source, selectDate])

  return (
    <div id="main">
      <div className={`content ${status}`}>
        <div className="leasing-availability">
          <FilterButton hasTitle={true} title="Lead Conversion" />
          <div className="line"></div>
          <div className="second-top">
            <button onClick={handleRedirectLeasingButton}>{"<"} BACK</button>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker onChange={datePickerHandler} disableFuture />
    </LocalizationProvider> */}
        <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DateRangePicker defaultValue={[dayjs(selectDate.startDate), dayjs(selectDate.endDate)]} onAccept={datePickerHandler} disableFuture/>
    </LocalizationProvider>
            <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>Lead Source</Typography>
        <Switch onChange={sourceToggler} color='default'/>
        <Typography>Leasing Agent</Typography>
      </Stack>
    </FormGroup>
          </div>
            {loader ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <>
              {response.length > 0 ? <LeasingSucessMore data={response} source={source}/> : <h1 style={{textAlign:'center',height:'66%'}}> <br></br>No leads found in this time period</h1>}
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default LeadConversion;
