import React from "react";
import { useSelector } from "react-redux";
import AvailabilityTable from "../../tables/availability";
import { useNavigate } from "react-router-dom";
import SelectBox from "../../../../components/selectbox";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getAvailability } from "../../../../api/api";
import CircularProgress from "@mui/material/CircularProgress";
import useSetFilterSelect from "../../../../hooks/useSetFilterSelect";
import FilterButton from "../../../../components/filterButton";

const Availability = () => {
  const navigate = useNavigate();
  const status = useSelector((state) => state.sidebar.value);
  const { response, loader, aux } = useFilterResponse(getAvailability, "availableDetail");
  const { dataFilter } = useSetFilterSelect(aux, ["status"])

  const handleRedirectLeasingButton = () => navigate("/leasing");

  return (
    <div id="main">
      <div className={`content ${status}`}>
        <div className={`leasing-availability ${status}`}>
          <FilterButton hasTitle={true} title="Availability" />
          <div className="line"></div>
          <div className="second-top">
            <button onClick={handleRedirectLeasingButton}>{"<"} BACK</button>
            {/* <SelectBox groups={dataFilter} slice='Availability' /> */}
          </div>
            {loader ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <>
              {response.length > 0 ? <AvailabilityTable res={response} />:<h1 style={{textAlign:'center',height:'66%'}}>Based on the current data, there is no availability right now.<br></br><br></br>Please note that this assessment is subject to change with new information or circumstances.</h1>}
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default Availability;
