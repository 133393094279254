import React, {useState, useEffect, useRef} from "react";
import useTableHeight from "../../../../hooks/useTableHeight";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getNotes } from "../../../../api/api";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import Note from "../../../../components/note";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";
import tooltips from "../../../../config/tooltips.json";
import { Tooltip, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';

const AgedTable = ({ data }) => {

  const [pageWidth, setPageWidth] = useState(null)
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  let functionDelaytimeOut

  window.addEventListener('resize', (size) => {
    let currentTarget = size?.currentTarget?.innerWidth
    if (functionDelaytimeOut) {
      clearTimeout(functionDelaytimeOut);
    }
    functionDelaytimeOut = setTimeout(() => {
      setPageWidth(currentTarget);
    }, 800);
  });

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
    return () => {
      if (functionDelaytimeOut) {
        clearTimeout(functionDelaytimeOut);
      }
    };
  }, [pageWidth]);


  const vwToPx = (vw) => (vw * width) / 100;

  const columnNames = [
    {name: "Property", type:'singleSelect', width:vwToPx(20)},
    {name: "0-30 Days % Notes", type:'number', width:vwToPx(5), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
    {name: "0-30 Days $", type:'number', width:vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name: "0-30 Days #", type:'number', width:vwToPx(5)},
    {name: "0-30 Days %", type:'number', width:vwToPx(5), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
    {name: "31-60 Days % Notes", type:'number', width:vwToPx(5), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
    {name: "31-60 Days $", type:'number', width:vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name: "31-60 Days #", type:'number', width:vwToPx(5)},
    {name: "31-60 Days %", type:'number', width:vwToPx(5), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
    {name: "61-90 Days % Notes", type:'number', width:vwToPx(5), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
    {name: "61-90 Days $", type:'number', width:vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name: "61-90 Days #", type:'number', width:vwToPx(5)},
    {name: "61-90 Days %", type:'number', width:vwToPx(5), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
    {name: "90+ Days % Notes", type:'number', width:vwToPx(5), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
    {name: "90+ Days $", type:'number', width:vwToPx(8), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name: "90+ Days #", type:'number', width:vwToPx(5)},
    {name: "90+ Days %", type:'number', width:vwToPx(5), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
  ];

  data = data.map((val, index)=>{
    return {
      id:index,
      propertyID: val.propertyID,
      name:val.name,
      firstNotes: val.first.notes,
      firstMoney: val.first.money,
      firstChart: val.first.chart,
      firstPercentage: val.first.percentage,
      secondNotes: val.second.notes,
      secondMoney: val.second.money,
      secondChart: val.second.chart,
      secondPercentage: val.second.percentage,
      thirdNotes: val.third.notes,
      thirdMoney: val.third.money,
      thirdChart: val.third.chart,
      thirdPercentage: val.third.percentage,
      fourthNotes: val.fourth.notes,
      fourthMoney: val.fourth.money,
      fourthChart: val.fourth.chart,
      fourthPercentage: val.fourth.percentage,
    }
  })

  const thresHoldValues = {
    firstNotes:{
      thresHoldValue:'0-30DaysPrecentNotes'
  }, 
  secondNotes:{
    thresHoldValue:'31-60DaysPrecentNotes'
  }, 
  thirdNotes:{
    thresHoldValue:'61-90DaysPrecentNotes'
  }, 
  fourthNotes:{
    thresHoldValue:'90+DaysPrecentNotes'
  }
}


  const columnGroupingModel = [
    {
      groupId: `0-30 Days`,
      headerName: `0-30 Days`,
      headerAlign: 'center',
      children: [{ field: 'firstNotes' }, { field: 'firstMoney' }, { field: 'firstChart' }, { field: 'firstPercentage' }],
    },
    {
      groupId: `31-60 Days`,
      headerName: `31-60 Days`,
      headerAlign: 'center',
      children: [{ field: 'secondNotes' }, { field: 'secondMoney' }, { field: 'secondChart' }, { field: 'secondPercentage' }],
    },
    {
      groupId: `61-90 Days`,
      headerName: `61-90 Days`,
      headerAlign: 'center',
      children: [{ field: 'thirdNotes' }, { field: 'thirdMoney' }, { field: 'thirdChart' }, { field: 'thirdPercentage' }],
    },
    {
      groupId: `90+ Days`,
      headerName: `90+ Days`,
      headerAlign: 'center',
      children: [{ field: 'fourthNotes' }, { field: 'fourthMoney' }, { field: 'fourthChart' }, { field: 'fourthPercentage' }],
    }
  ]

  const height = useTableHeight(data, 90, "550px");
  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "AgedDelinquency"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
  } = useNoteVisibility(listNotes, loader);
  const {getThresholdData} = useThreshold({view:'collections', table: 'AgedDelinquencies', loader})

  let { columenGen, thresHolds, notesColumnNameGen } = useMUIFilter(
    data,
    columnNames,
    // "Aged Delinquency"
  );

  let columns = columenGen();

  columns = columns.map((data, index) => {
    let headerName
    if(data.headerName.includes("% Notes")){
      headerName = '% Notes'
    }else if(data.headerName.includes("#")){
      headerName = '#'
    }else if(data.headerName.includes("%")){
      headerName = '%'
    }else if(data.headerName.includes("$")){
      headerName = '$'
    }else if(data.headerName.includes("%")){
      headerName = '%'
    }else{
      headerName = data.headerName
    }
  
    data.renderHeader = () => {
      return (
        <Tooltip
          placement="top"
          arrow
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={
            <React.Fragment>
  <Typography color="inherit">{tooltips['Aged Delinquency'][index]?.tooltip.header}</Typography>
            </React.Fragment>
          }
        >
          <p className="MuiDataGrid-columnHeaderTitle">{headerName}</p>
        </Tooltip>
      );
    };
    return data;
  })

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  return (
    <div className="container-table" style={{ height: height }} ref={elementRef}>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notesColumnNameGen()}
            table="AgedDelinquency"
            tableFullName="Aged Delinquency"
            tab="Collections"
          />
        </div>
      )}
       <DataGridPremium
  className="grid-style"
  rows={data}
  columns={columns}
  pinnedColumns={{left: ['name']}}
  slots={{ toolbar: ExportToolbar}}
  disableColumnMenu={true}
  columnGroupingModel={columnGroupingModel}
  experimentalFeatures={{ columnGrouping: true }}
  getCellClassName={(params) => {
    return `${thresHolds(params, getThresholdData,thresHoldValues)} ${handleCheckFlag(params.row.propertyID, params.colDef.colNum)}`
  }}
  onCellClick={(cell, event) => {
    handleNote(
      cell.row.propertyID,
      cell.colDef.colNum,
      cell.formattedValue
    )(event);
  }}
  disableVirtualization
  hideFooter
  disableColumnSelector
  initialState={{
    aggregation: {
      model: {
    firstMoney: 'sum',
    firstChart: 'sum',
    secondMoney: 'sum',
    secondChart: 'sum',
    thirdMoney: 'sum',
    thirdChart: 'sum',
    fourthMoney: 'sum',
    fourthChart: 'sum',
      },
    },
  }}
/>
    </div>
  );
};

export default AgedTable;

//Legacy Code
// import React from "react";
// import { money, percentage } from "../../../../functions/decimals";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import { getNotes } from "../../../../api/api";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import Note from "../../../../components/note";
// import { useThreshold } from "../../../../hooks/useThreshold";

// const AgedTable = ({ data }) => {
//   const columnNames = [
//     "Property",
//     "0-30 Days % Notes",
//     "0-30 Days $",
//     "0-30 Days #",
//     "0-30 Days %",
//     "31-60 Days % Notes",
//     "31-60 Days $",
//     "31-60 Days #",
//     "31-60 Days %",
//     "61-90 Days % Notes",
//     "61-90 Days $",
//     "61-90 Days #",
//     "61-90 Days %",
//     "90+ Days % Notes",
//     "90+ Days $",
//     "90+ Days #",
//     "90+ Days %",
//   ];
//   const height = useTableHeight(data, 90, "550px");
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "AgedDelinquency"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//   } = useNoteVisibility(listNotes, loader);
//   const {getThresholdData} = useThreshold({view:'collections', table: 'AgedDelinquencies', loader})

//   const totalfirstyM = data.reduce((acc, curr) => acc + curr?.first?.money, 0);
//   const totalfirstC = data.reduce((acc, curr) => acc + curr?.first?.chart, 0);
//   const totalsecondM = data.reduce((acc, curr) => acc + curr?.second?.money, 0);
//   const totalsecondC = data.reduce((acc, curr) => acc + curr?.second?.chart, 0);
//   const totalthirdM = data.reduce((acc, curr) => acc + curr?.third?.money, 0);
//   const totalthirdC = data.reduce((acc, curr) => acc + curr?.third?.chart, 0);
//   const totalfourtyM = data.reduce((acc, curr) => acc + curr?.fourth?.money, 0);
//   const totalfourtyC = data.reduce((acc, curr) => acc + curr?.fourth?.chart, 0);

//   return (
//     <div className="container-table" style={{ height: height }}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note
//             notes={notes}
//             columnNames={columnNames}
//             table="AgedDelinquency"
//           />
//         </div>
//       )}
//       <div className="wrapper">
//         <table className="hasColors">
//           <thead>
//             <tr>
//               <th>Property</th>
//               <th colSpan={4} className="subparent">
//                 0-30 Days
//               </th>
//               <th colSpan={4} className="subparent">
//                 31-60 Days
//               </th>
//               <th colSpan={4} className="subparent">
//                 61-90 Days
//               </th>
//               <th colSpan={4} className="subparent">
//                 90+ Days
//               </th>
//             </tr>
//             <tr className="subchild">
//               <th></th>
//               <th>% Notes</th>
//               <th>$</th>
//               <th>#</th>
//               <th>%</th>
//               <th>% Notes</th>
//               <th>$</th>
//               <th>#</th>
//               <th>%</th>
//               <th>% Notes</th>
//               <th>$</th>
//               <th>#</th>
//               <th>%</th>
//               <th>% Notes</th>
//               <th>$</th>
//               <th>#</th>
//               <th>%</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td
//                   onClick={handleNote(item?.propertyID, 0, item?.name, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`}
//                 >
//                   {item?.name}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 1, item?.first?.notes, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 1)}
//                   ${getThresholdData(parseInt(item?.first?.notes), '0-30DaysPrecentNotes')}`}
//                 >
//                   {item?.first?.notes}%
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 2, item?.first?.money, columnNames)}
//                   className={ `${handleCheckFlag(item?.propertyID, 2)}`}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.first?.money)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 3, item?.first?.chart, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 3)}
//                 >
//                   {item?.first?.chart}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 4, item?.first?.percentage, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 4)}
//                 >
//                   {percentage(item?.first?.percentage)}%
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 5, item?.second?.notes, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 5)}
//                   ${getThresholdData(parseInt(item?.second?.notes), '31-60DaysPrecentNotes')}`}
//                 >
//                   {item?.second?.notes}%
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 6, item?.second?.money, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 6)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.second?.money)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 7, item?.second?.chart, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 7)}
//                 >
//                   {item?.second?.chart}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 8, item?.second?.percentage, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 8)}
//                 >
//                   {percentage(item?.second?.percentage)}%
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 9, item?.third?.notes, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 9)} 
//                   ${getThresholdData(parseInt(item?.third?.notes), '61-90DaysPrecentNotes')}`}
//                 >
//                   {item?.third?.notes}%
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 10, item?.third?.money, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 10)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.third?.money)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 11, item?.third?.chart, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 11)}
//                 >
//                   {item?.third?.chart}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 12, item?.third?.percentage, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 12)}
//                 >
//                   {percentage(item?.third?.percentage)}%
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 13, item?.fourth?.notes, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 13)}
//                   ${getThresholdData(parseInt(item?.third?.notes), '90+DaysPrecentNotes')}`}
//                 >
//                   {item?.fourth?.notes}%
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 14, item?.fourth?.money, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 14)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.fourth?.money)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 15, item?.fourth?.chart, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 15)}
//                 >
//                   {item?.fourth?.chart}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 16, item?.fourth?.percentage, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 16)}
//                 >
//                   {percentage(item?.fourth?.percentage)}%
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td></td>
//               <td style={{textAlign:'right'}}>{money(totalfirstyM)}</td>
//               <td>{totalfirstC}</td>
//               <td></td>
//               <td></td>
//               <td style={{textAlign:'right'}}>{money(totalsecondM)}</td>
//               <td>{totalsecondC}</td>
//               <td></td>
//               <td></td>
//               <td style={{textAlign:'right'}}>{money(totalthirdM)}</td>
//               <td>{totalthirdC}</td>
//               <td></td>
//               <td></td>
//               <td style={{textAlign:'right'}}>{money(totalfourtyM)}</td>
//               <td>{totalfourtyC}</td>
//               <td></td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default AgedTable;
