import React,{useState, useRef, useEffect} from "react";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import { useGridApiRef, DataGridPremium } from "@mui/x-data-grid-premium";

const RecentNotes = ({data}) => {

  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  const vwToPx = (vw) => (vw * width) / 100;

  const columnNames = [
    { name: "Name", width: vwToPx(35) },
    { name: `${data[0].date1}`, width: vwToPx(15) },
    { name: `${data[0].date2}`, width: vwToPx(15) },
    { name: `${data[0].date3}`, width: vwToPx(15) },
    { name: `${data[0].date4}`, width: vwToPx(15) },
    { name: `${data[0].date5}`, width: vwToPx(15) },
    { name: `${data[0].date6}`, width: vwToPx(15) },
    { name: `${data[0].date7}`, width: vwToPx(15) },
  ];

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  let { columenGen } = useMUIFilter(data, columnNames);
  const apiRef = useGridApiRef();
  let columns = columenGen();

  return (
      <div style={{height:'70%', width:'99%'}} ref={elementRef}>
      <DataGridPremium
        className="grid-style"
        apiRef={apiRef}
        rows={data}
        columns={columns}
        disableColumnMenu={true}
        hideFooter
        disableVirtualization
        disableColumnSelector
        pinnedColumns={{ left: ["userName"] }}
      />
      </div>
  );
};

export default RecentNotes;

// import React from "react";
// import FlagIcon from "@mui/icons-material/Flag";

// const FlagChart = () => {
//   const data = [
//     {
//       name: "Adam Parks Apartments",
//       occupancy: true,
//       leasing: false,
//       maintenance: true,
//       collections: true,
//       budget: true,
//     },
//     {
//       name: "Cantfield Apartments, LLC.",
//       occupancy: false,
//       leasing: true,
//       maintenance: false,
//       collections: true,
//       budget: false,
//     },
//     {
//       name: "Draper Lofts Apartments",
//       occupancy: false,
//       leasing: false,
//       maintenance: true,
//       collections: false,
//       budget: true,
//     },
//     {
//       name: "Grover Cleveland Apartments",
//       occupancy: true,
//       leasing: true,
//       maintenance: false,
//       collections: true,
//       budget: false,
//     },
//     {
//       name: "Halfmoon Garden Apartments LLC.",
//       occupancy: false,
//       leasing: false,
//       maintenance: false,
//       collections: true,
//       budget: false,
//     },
//   ];

//   const displayFlag = (variable) => {
//     if (variable) return <FlagIcon sx={{ color: "#BA0101" }} />;
//     else return null;
//   };

//   return (
//     <div className="container-table">
//       <div className="wrapper">
//         <table>
//           <thead>
//             <tr>
//               <th className="subparent">Property</th>
//               <th>Occupancy</th>
//               <th>Leasing</th>
//               <th>Maintenance</th>
//               <th>Collections</th>
//               <th>Budget</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td>{item?.name}</td>
//                 <td>{displayFlag(item?.occupancy)}</td>
//                 <td>{displayFlag(item?.leasing)}</td>
//                 <td>{displayFlag(item?.maintenance)}</td>
//                 <td>{displayFlag(item?.collections)}</td>
//                 <td>{displayFlag(item?.budget)}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default FlagChart;
