import React, { useState, useEffect } from "react";
import useTableHeight from "../../../../hooks/useTableHeight";
import { getNotes } from "../../../../api/api";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import Note from "../../../../components/note";
import { useThreshold } from "../../../../hooks/useThreshold";
import { useGridApiRef } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";

const AvailabilityTable = ({ res }) => {

  const apiRef = useGridApiRef();
  
  const columnNames = [
    { name: "Property", type: "singleSelect" },
    { name: "Unit", type: "number", minWidth: 80},
    { name: "Status", type: "singleSelect", minWidth: 250},
    { name: "Move-Out", type: "date" },
    { name: "Days Vacant", type: "number" },
    { name: "Days Until Move-Out", type: "number"},
    { name: "Days to Rent", type: "number"},
    { name: "Expected Days Vacant", type: "number"},
    { name: "Application Completed", type: "date" },
    { name: "Days to Process", type: "number"},
    { name: "Application Approved", type: "date" },
    { name: "Days To Sign", type: "number"},
    { name: "Lease Completed", type: "date", type: "number"},
    { name: "Lead", type:'singleSelect'},
    { name: "Scheduled Move-In", type: "date" },
    { name: "Scheduled Turn Completion", type: "date" },
    { name: "Available On", type: "date" },
  ];

  const thresHoldValues = {
    DaysVacant:{
      thresHoldValue:'DaysVacant'
    }, DaysToRent:{
      thresHoldValue:'DaysToRent'
    }, ExpectedDaysVacant:{
      thresHoldValue:'ExpectedDaysVacant'
    }, InProgressDayToProcess:{
      thresHoldValue:'InProgressDayToProcess'
    }, PostApprovedDaysToSign:{
      thresHoldValue:'PostApprovedDaysToSign'
    }
  }

  let { columenGen, thresHolds, notesColumnNameGen } = useMUIFilter(
    res,
    columnNames,
    "Availability"
  );

  let columns = columenGen();

  const [data, setData] = useState(res);
  const height = useTableHeight(res, 75, 500);
  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "Availability"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlagforUnits,
  } = useNoteVisibility(listNotes, loader);

  const { getThresholdData } = useThreshold({
    view: "leasing",
    table: "Availability",
    loader,
  });

  useEffect(() => {
    setData(res);
  }, [res]);

  if (apiRef?.current?.autosizeColumns) {
    apiRef.current.autosizeColumns({
      includeHeaders: true,
      includeOutliers: true,
    });
  }

  return (
    <>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notesColumnNameGen()}
            table="Availability"
            tableFullName="Availability"
            tab="Leasing"
          />
        </div>
      )}
      <DataGridPremium
  className="grid-style"
  rows={res}
  columns={columns}
  pinnedColumns={{left: ['name', 'bldg']}}
  onCellClick={(cell,event)=>{handleNote(cell.row.propertyID, cell.colDef.colNum, cell.formattedValue, cell.row.bldg)(event)}}
  getCellClassName={(params) => {
    return `${thresHolds(params, getThresholdData, thresHoldValues)} ${handleCheckFlagforUnits(params.row.propertyID, params.colDef.colNum, params.row.bldg)}`
  }}
  slots={{ toolbar: ExportToolbar}}
  disableColumnMenu={true}
  disableVirtualization
  hideFooter
  disableColumnSelector
  apiRef={apiRef}
/>
    </>
  );
};

export default AvailabilityTable;


//Legacy Code
// import React, { useState, useEffect } from "react";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
// import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
// import { getNotes } from "../../../../api/api";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import Note from "../../../../components/note";
// import { useThreshold } from "../../../../hooks/useThreshold";
// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import { useGridApiRef } from "@mui/x-data-grid";
// import { DataGridPro } from '@mui/x-data-grid-pro';
// import { useSelector } from "react-redux";
// import useMUIFilter from "../../../../hooks/useMUIFilter";

// const AvailabilityTable = ({ res }) => {

//   const columnNames = [
//     {name:"Property", type:'singleSelect'},
//     {name:"BLDG Unit", type:'number'},
//     {name:"Status", type: 'singleSelect'},
//     {name:"Move-Out", type:'date'},
//     {name:"Days Vacant", type:'Number', threshold:true},
//     {name:"Days Until Move-Out"},
//     {name:"Days to Rent", threshold:true},
//     {name:"Expected Days Vacant", threshold:true},
//     {name:"Application Completed",type:'date'},
//     {name:"In Progress Day to Process", threshold:true},
//     {name:"Application Approved", type:'date'},
//     {name:"Post Approved Days To Sign", threshold:true},
//     {name:"Lease Completed", type:'date'},
//     {name:"Lead"},
//     {name:"Schedule Move-In",type:'date'},
//     {name:"Schedule Turn Completion",type:'date'},
//     {name:"Available On",type:'date'},
//   ];

//   let {columenGen, thresHolds, notesColumnNameGen} = useMUIFilter(res, columnNames)


// let columns = columenGen()

//   function getPropertyNames() {
//     // Extract names from the objects and filter duplicates
//     let uniqueNames = [...new Set(res.map(item => item.name))];
    
    
//     return uniqueNames;
// }

// function getStatus() {
//   let uniqueNames = [...new Set(res.map(item => item.status))];
//   return uniqueNames;
// }

//   const columnNames2 = [
//     { field: "name", headerName: "Name", hideable: false, valueOptions: getPropertyNames(), valueFormatter: ({ value }) => value, type: 'singleSelect', colNum: 0, headerAlign: 'center'},
//     { field: "bldg", headerName: "BLDG", hideable: false, colNum: 1, headerAlign: 'center' },
//     { field: "status", headerName: "Status", hideable: false, colNum: 2, headerAlign: 'center', valueOptions: getStatus(), valueFormatter: ({ value }) => value, type: 'singleSelect' },
//     { field: "moveOut", headerName: "Move-Out", type: 'date', hideable: false, colNum: 3, headerAlign: 'center',valueFormatter: (params) => {return params.value}, type:'date'},
//     { field: "DaysVacant", headerName: "Days Vacant", hideable: false, colNum: 4, headerAlign: 'center', type:'number', availableAggregationFunctions:['avg', 'min', 'max'] },
//     { field: "daysUnitMoveOut", headerName: "Days Until Move-Out", colNum: 5, headerAlign: 'center' },
//     { field: "daysToRent", headerName: "Days to Rent", colNum: 6, headerAlign: 'center' },
//     { field: "expectedDaysV", headerName: "Expected Days Vacant", colNum: 7, headerAlign: 'center' },
//     { field: "applicationC", headerName: "Application Completed", headerAlign: 'center',valueFormatter: (params) => {return params.value}, type:'date' },
//     { field: "inProgress", headerName: "In Progress Day to Process", colNum: 8, headerAlign: 'center' },
//     { field: "applicationA", headerName: "Application Approved", colNum: 9, headerAlign: 'center', valueFormatter: (params) => {return params.value}, type:'date' },
//     { field: "postApprDTS", headerName: "Post Approved Days To Sign", colNum: 10, headerAlign: 'center' },
//     { field: "leaseC", headerName: "Lease Completed", colNum: 11, headerAlign: 'center', valueFormatter: (params) => {return params.value}, type:'date' },
//     { field: "lead", headerName: "Lead", colNum: 12, headerAlign: 'center' },
//     { field: "scheduleMI", headerName: "Schedule Move-In", colNum: 13, headerAlign: 'center', valueFormatter: (params) => {return params.value}, type:'date' },
//     { field: "scheduleTC", headerName: "Schedule Turn Completion", colNum: 14, headerAlign: 'center', valueFormatter: (params) => {return params.value}, type:'date' },
//     { field: "availableOn", headerName: "Available On", colNum: 15, headerAlign: 'center', valueFormatter: (params) => {return params.value}, type:'date'}
// ]

//   const [data, setData] = useState(res);
//   const height = useTableHeight(res,75, 500);
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "Availability"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//     handleCheckFlagforUnits
//   } = useNoteVisibility(listNotes, loader);

//   const [sortOrder, setSortOrder] = useState("asc");

//   const {getThresholdData} = useThreshold({view:'leasing', table: 'Availability', loader})
//   const handleSort = () => {
//     const sortedData = [...data]?.sort((a, b) => {
//       const daysVacantA = parseInt(a.daysVacant);
//       const daysVacantB = parseInt(b.daysVacant);

//       if (sortOrder === "asc") {
//         return daysVacantA - daysVacantB;
//       } else {
//         return daysVacantB - daysVacantA;
//       }
//     });

//     setSortOrder(sortOrder === "asc" ? "desc" : "asc");
//     setData(sortedData);
//   };

//   const toggleButtons = () => {
//     if (sortOrder === "asc") return <KeyboardArrowDownOutlinedIcon />;
//     else return <KeyboardArrowUpOutlinedIcon />;
//   };

//   const apiRef = useGridApiRef();

//   useEffect(() => {
//     setData(res)
//   }, [res])

// if(apiRef?.current?.autosizeColumns){
//   apiRef.current.autosizeColumns({
//     // includeHeaders: true,
//     includeOutliers: true,
//   })
// }

//   return (
//     // <div className="content"> 
//     //     <table className="table">
//     //     {showComponent && (
//     //     <div
//     //       style={{
//     //         position: "absolute",
//     //         left: componentPosition.x,
//     //         top: componentPosition.y,
//     //         backgroundColor: "#fff",
//     //         border: "1px solid #ccc",
//     //         padding: "10px",
//     //         zIndex: "10",
//     //         borderRadius: "10px",
//     //       }}
//     //     >
//     //       <Note notes={notes} columnNames={columnNames} table="Availability" />
//     //     </div>
//     //   )}
//     //       <thead>
//     //         <tr>
//     //           <th>Property</th>
//     //           <th>BLDG Unit</th>
//     //           <th>Status</th>
//     //           <th>Move-Out</th>
//     //           <th onClick={handleSort}>Days Vacant {toggleButtons()}</th>
//     //           <th>Days Until Move-Out</th>
//     //           <th>Days to Rent</th>
//     //           <th>Expected Days Vacant</th>
//     //           <th>Application Completed</th>
//     //           <th>In Progress Day to Process</th>
//     //           <th>Application Approved</th>
//     //           <th>Post Approved Days To Sign</th>
//     //           <th>Lease Completed</th>
//     //           <th>Lead</th>
//     //           <th>Schedule Move-In</th>
//     //           <th>Schedule Turn Completion</th>
//     //           <th>Available On</th>
//     //         </tr>
//     //       </thead>
//     //       <tbody>
//     //         {data?.map((item, index) => (
//     //           <tr key={index}>
//     //             <td
//     //               // onClick={handleNote(item?.propertyID, 0, item?.name, columnNames)}
//     //               className={`${handleCheckFlag(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`}
//     //             >
//     //               {item?.name}
//     //             </td>
//     //             <td
//     //               // onClick={handleNote(item?.propertyID, 1, item?.bldg, columnNames)}
//     //               className={handleCheckFlag(item?.propertyID, 1)}
//     //             >
//     //               {item?.bldg}
//     //             </td>
//     //             <td
//     //               // onClick={handleNote(item?.propertyID, 2, item?.status, columnNames)}
//     //               className={handleCheckFlag(item?.propertyID, 2)}
//     //             >
//     //               {item?.status}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 3, item?.moveOut, item?.bldg)}
//     //               className={handleCheckFlagforUnits(item?.propertyID, 3, item?.bldg)}
//     //             >
//     //               {item?.moveOut}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 4, item?.daysVacant, item?.bldg)}
//     //               className={`${item?.daysVacant ? getThresholdData(parseInt(item?.daysVacant), 'DaysVacant') : null}
//     //               ${handleCheckFlagforUnits(item?.propertyID, 4, item?.bldg)}`}
//     //             >
//     //               {item?.daysVacant}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 5, item?.daysUnitMoveOut, item?.bldg)}
//     //               className={handleCheckFlagforUnits(item?.propertyID, 5, item?.bldg)}
//     //             >
//     //               {item?.daysUnitMoveOut}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 6, item?.daysToRent, item?.bldg)}
//     //               className={`${handleCheckFlagforUnits(item?.propertyID, 6, item?.bldg)}
//     //               ${item?.daysToRent ? getThresholdData(parseInt(item?.daysToRent), 'DaysToRent') : null}`}
//     //             >
//     //               {item?.daysToRent}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 7, item?.expectedDaysV, item?.bldg)}
//     //               className={`${handleCheckFlagforUnits(item?.propertyID, 7, item?.bldg)}
//     //               ${item?.expectedDaysV ? getThresholdData(parseInt(item?.expectedDaysV), 'ExpectedDaysVacant') : null}`}
//     //             >
//     //               {item?.expectedDaysV}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 8, item?.applicationC, item?.bldg)}
//     //               className={handleCheckFlagforUnits(item?.propertyID, 8, item?.bldg)}
//     //             >
//     //               {item?.applicationC}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 9, item?.inProgress, item?.bldg)}
//     //               className={`${handleCheckFlagforUnits(item?.propertyID, 9, item?.bldg)}
//     //               ${item?.inProgress ? getThresholdData(parseInt(item?.inProgress), 'InProgressDayToProcess') : null}`}
//     //             >
//     //               {item?.inProgress}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 10, item?.applicationA, item?.bldg)}
//     //               className={handleCheckFlagforUnits(item?.propertyID, 10, item?.bldg)}
//     //             >
//     //               {item?.applicationA}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 11, item?.postApprDTS, item?.bldg)}
//     //               className={`${handleCheckFlagforUnits(item?.propertyID, 11, item?.bldg)}
//     //               ${item?.postApprDTS ? getThresholdData(parseInt(item?.postApprDTS), 'PostApprovedDaysToSign') : null}`}
//     //             >
//     //               {item?.postApprDTS}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 12, item?.leaseC, item?.bldg)}
//     //               className={handleCheckFlagforUnits(item?.propertyID, 12, item?.bldg)}
//     //             >
//     //               {item?.leaseC}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 13, item?.lead, item?.bldg)}
//     //               className={handleCheckFlagforUnits(item?.propertyID, 13, item?.bldg)}
//     //             >
//     //               {item?.lead}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 14, item?.scheduleMI, item?.bldg)}
//     //               className={handleCheckFlagforUnits(item?.propertyID, 14, item?.bldg)}
//     //             >
//     //               {item?.scheduleMI}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 15, item?.scheduleTC, item?.bldg)}
//     //               className={handleCheckFlagforUnits(item?.propertyID, 15, item?.bldg)}
//     //             >
//     //               {item?.scheduleTC}
//     //             </td>
//     //             <td
//     //               onClick={handleNote(item?.propertyID, 16, item?.availableOn, item?.bldg)}
//     //               className={handleCheckFlagforUnits(item?.propertyID, 16, item?.bldg)}
//     //             >
//     //               {item?.availableOn}
//     //             </td>
//     //           </tr>
//     //         ))}
//     //       </tbody>
//     //     </table>
//     // </div>
// <>

//         {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note notes={notes} columnNames={notesColumnNameGen()} table="Availability" />
//         </div>
//       )}

// <DataGridPro
// className="grid-style"
//   apiRef={apiRef}
//   rows={res}
//   columns={columns}
//   // columns={columnNames2}
//   pinnedColumns={{left: ['name', 'bldg']}}
//   // onRowClick={(params,event) => { handleNote(params.row.propertyID, 0, params.row.name, params.field)(event) }}
//   // onColumnHeaderClick={(event, column) => console.log(column)}
//   onCellClick={(cell,event)=>{handleNote(cell.row.propertyID, cell.colDef.colNum, cell.formattedValue, cell.row.bldg)(event)}}
//   getCellClassName={(params) => {
//     return `${thresHolds(params, getThresholdData,{DaysVacant:true, DaysToRent:true, ExpectedDaysVacant:true, InProgressDayToProcess:true, PostApprovedDaysToSign:true})} ${handleCheckFlagforUnits(params.row.propertyID, params.colDef.colNum, params.row.bldg)}`
//   }}
//   disableVirtualization
//   hideFooter
// />
// </>
//   );
// };

// export default AvailabilityTable;
