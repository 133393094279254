import React, {useState, useRef, useEffect} from "react";
import useTableHeight from "../../../../hooks/useTableHeight";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getNotes } from "../../../../api/api";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import Note from "../../../../components/note";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";
import tooltips from "../../../../config/tooltips.json";
import { Tooltip, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';

const CurrentDT = ({ data }) => {

  const [pageWidth, setPageWidth] = useState(null)
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  let functionDelaytimeOut

  window.addEventListener('resize', (size) => {
    let currentTarget = size?.currentTarget?.innerWidth
    if (functionDelaytimeOut) {
      clearTimeout(functionDelaytimeOut);
    }
    functionDelaytimeOut = setTimeout(() => {
      setPageWidth(currentTarget);
    }, 800);
  });

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
    return () => {
      if (functionDelaytimeOut) {
        clearTimeout(functionDelaytimeOut);
      }
    };
  }, [pageWidth]);


  const vwToPx = (vw) => (vw * width) / 100;

  const columnNames = [
    {name:"Property", type:'singleSelect', width:vwToPx(30),},
    {name:"Past Due $", type:'number', width:vwToPx(13), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name:"Past Due #", type:'number', width:vwToPx(13),},
    {name:"Past Due %", type:'number', width:vwToPx(12), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
    {name:"Largest Delinquent Resident $", type:'number', width:vwToPx(13), valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name:"Delinquent over $250", type:'number', width:vwToPx(18)},
  ];

  const columnGroupingModel = [
    {
      groupId: `Past Due`,
      headerName: `Past Due`,
      headerAlign: 'center',
      children: [{ field: 'pastDueMoney' }, { field: 'pastDuePercentage' }, { field: 'pastDueChart' }],
    },
    {
      groupId: `Largest Delinquent Resident`,
      headerName: `Largest Delinquent Resident`,
      headerAlign: 'center',
      children: [{ field: 'largeDelinquency' }],
    },
  ]

  data = data.map((val, index)=>{
    return {
      id:index,
      propertyID: val.propertyID,
      name:val.name,
      pastDueMoney: val.pastDue.money,
      pastDueChart: val.pastDue.chart,
      pastDuePercentage: val.pastDue.percentage,
      largeDelinquency: val.largeDelinquency,
      delinquency: val.delinquency
    }
  })

  const height = useTableHeight(data, 90, "450px");
  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "CurrentDelinquencies"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
  } = useNoteVisibility(listNotes, loader);
  const {getThresholdData} = useThreshold({view:'collections', table: 'CurrentDelinquencies', loader})

  let { columenGen, thresHolds, notesColumnNameGen } = useMUIFilter(
    data,
    columnNames,
  );

  let columns = columenGen();

columns = columns.map((data, index) => {
  let headerName
  if(data.headerName === "Past Due $"){
    headerName = '$'
  }else if(data.headerName === "Past Due #"){
    headerName = '#'
  }else if(data.headerName === "Past Due %"){
    headerName = '%'
  }else if(data.headerName === "Largest Delinquent Resident $"){
    headerName = '$'
  }else{
    headerName = data.headerName
  }

  data.renderHeader = () => {
    return (
      <Tooltip
        placement="top"
        arrow
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        title={
          <React.Fragment>
<Typography color="inherit">{tooltips['Current Delinquencies'][index]?.tooltip.header}</Typography>
          </React.Fragment>
        }
      >
        <p className="MuiDataGrid-columnHeaderTitle">{headerName}</p>
      </Tooltip>
    );
  };
  return data;
})

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  return (
    <div className="container-table" style={{ height: height }} ref={elementRef}>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notesColumnNameGen()}
            table="CurrentDelinquencies"
            tableFullName="Current Delinquencies"
            tab="Collections"
          />
        </div>
      )}
 <DataGridPremium
  className="grid-style"
  rows={data}
  columns={columns}
  pinnedColumns={{left: ['name']}}
  slots={{ toolbar: ExportToolbar}}
  disableColumnMenu={true}
  columnGroupingModel={columnGroupingModel}
  experimentalFeatures={{ columnGrouping: true }}
  getCellClassName={(params) => {
    return `${handleCheckFlag(params.row.propertyID, params.colDef.colNum)}`
  }}
  onCellClick={(cell, event) => {
    handleNote(
      cell.row.propertyID,
      cell.colDef.colNum,
      cell.formattedValue
    )(event);
  }}
  disableVirtualization
  hideFooter
  disableColumnSelector
  initialState={{
    aggregation: {
      model: {
        pastDuePercentage: 'avg',
        pastDueMoney:'sum',
        currentTurns: 'sum',
        pastDueChart:'sum',
        largeDelinquency:'sum',
        delinquency:'sum'
      },
    },
  }}
/>
    </div>
  );
};

export default CurrentDT;

//Legacy Code
// import React from "react";
// import { money, percentage } from "../../../../functions/decimals";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import { getNotes } from "../../../../api/api";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import Note from "../../../../components/note";
// import { useThreshold } from "../../../../hooks/useThreshold";

// const CurrentDT = ({ data }) => {
//   const columnNames = [
//     "Property",
//     "Past Due $",
//     "Past Due #",
//     "Past Due %",
//     "Largest Delinquent Resident",
//     "Delinquent over $250",
//   ];
//   const height = useTableHeight(data, 90, "450px");
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "CurrentDelinquencies"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//   } = useNoteVisibility(listNotes, loader);
//   const {getThresholdData} = useThreshold({view:'collections', table: 'CurrentDelinquencies', loader})

//   const totalPDM = data.reduce((acc, curr) => acc + curr?.pastDue?.money, 0);
//   const totalPDC = data.reduce((acc, curr) => acc + curr?.pastDue?.chart, 0);
//   const totalPer = data.reduce(
//     (acc, curr) => acc + curr?.pastDue?.percentage,
//     0
//   );

//   // const totallargest = data.reduce(
//   //   (acc, curr) => acc + curr?.largeDelinquency,
//   //   0
//   // );
//   const totallargest = data.reduce((max, curr) => curr?.largeDelinquency > max? curr.largeDelinquency : max,0);
//   const totaldel = data.reduce((acc, curr) => acc + curr?.delinquency, 0);
  

//   return (
//     <div className="container-table" style={{ height: height }}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note
//             notes={notes}
//             columnNames={columnNames}
//             table="CurrentDelinquencies"
//           />
//         </div>
//       )}
//       <div className="wrapper">
//         <table>
//           <thead>
//             <tr>
//               <th>Property</th>
//               <th colSpan={3} className="subparent">
//                 Past Due
//               </th>
//               <th className="subparent">Largest Delinquent Resident</th>
//               <th className="subparent">Delinquent over $250</th>
//             </tr>
//             <tr className="subchild">
//               <th></th>
//               <th>$</th>
//               <th>#</th>
//               <th>%</th>
//               <th>$</th>
//               <th></th>
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td
//                   onClick={handleNote(item?.propertyID, 0, item?.name, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`}
//                 >
//                   {item?.name}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 1, item?.pastDue?.money, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 1)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.pastDue?.money)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 2, item?.pastDue?.chart, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 2)}
//                 >
//                   {item?.pastDue?.chart}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 3, item?.pastDue?.percentage, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 3)}
//                   ${getThresholdData(parseInt(item?.pastDue?.percentage), 'PastDuePercent')}`}
//                 >
//                   {percentage(item?.pastDue?.percentage)}%
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 4, item?.largeDelinquency, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 4)}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.largeDelinquency)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 5, item?.delinquency, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 5)}
//                   ${getThresholdData(parseInt(item?.delinquency), 'DelinquencyOverXDollars')}`}
//                 >
//                   {parseInt(item?.delinquency)}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td style={{textAlign:'right'}}>{money(totalPDM)}</td>
//               <td>{totalPDC}</td>
//               <td>{percentage(totalPer / data?.length)}%</td>
//               <td style={{textAlign:'right'}}>{money(totallargest)}</td>
//               <td>{parseInt(totaldel)}</td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default CurrentDT;

