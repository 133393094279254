import { createSlice } from "@reduxjs/toolkit";

export const noteSlice = createSlice({
  name: "note",
  initialState: {
    show: "closed",
    showAllNotes: "closed",
    showAddNote: "closed",
    showPreviewNotes: false,
    listAllNotes: [],
    currentTable: "",
    columns: [],
    currentProperty: 0,
    currentColumn: 0,
    currentColumnValue:null,
    bldgValue:null,
    accountValue:null,
    sourceValue: null
  },
  reducers: {
    noteStatus: (state, action) => {
      state.show = action.payload;
    },
    setShowPreviewNotes: (state, action) => {
      state.showPreviewNotes = action.payload;
    },
    allNotesStatus: (state, action) => {
      state.showAllNotes = action.payload;
    },
    addNoteStatus: (state, action) => {
      state.showAddNote = action.payload;
    },
    setListAllNotes: (state, action) => {
      state.listAllNotes = action.payload;
    },
    setTableName: (state, action) => {
      state.currentTable = action.payload;
    },
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    setCurrentProperty: (state, action) => {
      state.currentProperty = action.payload;
    },
    setCurrentColumn: (state, action) => {
      state.currentColumn = action.payload;
    },
    setCurrentColumnValue: (state, action) => {
      state.currentColumnValue = action.payload;
    },
    setbldgValue: (state, action) => {
      state.bldgValue = action.payload;
    },
    setAccountValue: (state, action) => {
      state.accountValue = action.payload;
    },
    setSourceValue: (state, action) => {
      state.sourceValue = action.payload;
    },
    setFullTableName: (state, action) => {
      state.tableFullName = action.payload
    },
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setColumnName: (state, action) => {
      state.columnName = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const { noteStatus, allNotesStatus, setListAllNotes, setTableName, setColumns, setCurrentProperty, setCurrentColumn, setShowPreviewNotes, setCurrentColumnValue, setbldgValue, setAccountValue, setSourceValue, setFullTableName, setTab, setColumnName } = noteSlice.actions;

export default noteSlice.reducer;