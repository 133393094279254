const GET_API_URLS_ENV = () => {
    
    // if(!process.env.MAIN_URI)
    //     throw 'process.env.MAIN_URI returns unknown value';
    // if(!process.env.MAIN_URI_DEV)
    //     throw 'process.env.MAIN_URI_DEV returns unknown value';
    // if(!process.env.MAIN_URI_DEMO)
    //     throw 'process.env.MAIN_URI_DEMO returns unknown value';


    // console.log("process.env: ", process.env);
    // console.log("process.env.REACT_APP_MAIN_API_URI: ", process.env.REACT_APP_MAIN_API_URI);
    // console.log("process.env.REACT_APP_MAIN_API_URI_DEV: ", process.env.REACT_APP_MAIN_API_URI_DEV);
    // console.log("process.env.REACT_APP_MAIN_API_URI_DEMO: ", process.env.REACT_APP_MAIN_API_URI_DEMO);

    if(!process.env.REACT_APP_MAIN_API_URI)
        throw 'process.env.REACT_APP_MAIN_API_URI returns unknown value';
    if(!process.env.REACT_APP_MAIN_API_URI_DEV)
        throw 'process.env.REACT_APP_MAIN_API_URI_DEV returns unknown value';
    if(!process.env.REACT_APP_MAIN_API_URI_DEMO)
        throw 'process.env.REACT_APP_MAIN_API_URI_DEMO returns unknown value';

    return {
        // mainUri: process.env.MAIN_URI,
        // mainUriDev: process.env.MAIN_URI_DEV,
        // mainUriDemo: process.env.MAIN_URI_DEMO,
        mainApiUri: process.env.REACT_APP_MAIN_API_URI,
        mainApiUriDev: process.env.REACT_APP_MAIN_API_URI_DEV,
        mainApiUriDemo: process.env.REACT_APP_MAIN_API_URI_DEMO,
    }
    
}

export default GET_API_URLS_ENV;