import React, { useEffect } from "react";
import RenewalsMoreTable from "../../tables/renewalsMoreTable/renewalsMoreTable";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { generateRenewalsDetails } from "../../../../api/api";
import CircularProgress from "@mui/material/CircularProgress";
import useFilter from "../../../../hooks/useFilter";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { TablePagination } from "@mui/material";

const RenewalsMore = () => {
  const navigate = useNavigate();
  const { handleToggleFilter } = useFilter();

  const status = useSelector((state) => state.sidebar.value);
  const filterArray = useSelector((state) => state.filter.tableFilter);

  const { response, loader, PagResponse, fetchData, setLoader } =
    useFilterResponse(generateRenewalsDetails, "data", "", true);

  const handleRedirectOccupancyButton = () => navigate("/occupancy");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    setPage(newPage)
    // fetchData({ rows: rowsPerPage, page: newPage + 1 });
    fetchData({ rows: rowsPerPage, page:newPage});
  };

  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));

    // fetchData({ rows: event.target.value, page: 1 });
    fetchData({ rows: event.target.value, page:page });
  };

  useEffect(() => {
      // fetchData({ rows: 25, page:page });
  },[filterArray])

  return (
    <div id="main">
      <div className={`content ${status}`} style={{height: '100vh'}}>
        <div className={`occupancy-renew ${status}`} style={{height: 'auto'}}>
          <div className="top">
            <h2>Renewals</h2>
            <button className="blue" onClick={handleToggleFilter}>
              PROPERTIES FILTERS
            </button>
          </div>
          <div className="line"></div>
          <div className="second-top">
            <button onClick={handleRedirectOccupancyButton}>{"<"} BACK</button>
            {/* <select></select> */}
          </div>
          <div style={{ marginBottom: "2rem" }}>
            {loader ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : response.length > 0 ? (<>
            
              <RenewalsMoreTable data={response} />
              <TablePagination
                count={PagResponse?.totalRows}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              </>
            ) : (
              <div style={{ textAlign: "center" }}><h1 style={{textAlign:'center'}}><br></br>Based on the data currently available, no actions are required on your lease renewals at this time. <br></br><br></br>Please note that this assessment is subject to change with new information or circumstances</h1></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenewalsMore;
