import { useState, useEffect } from "react";
import { filterResponse, filterResponseMoreData, handleData } from "../functions/filterArray";
import { useSelector } from "react-redux";

const useFilterResponse = (apiFunction, object, route = "", hasPaginator = false) => {
  const filterArray = useSelector((state) => state.filter.tableFilter);
  const filterAvailabilityStatus = useSelector((state) => state.filterMoreDetails.filterAvailabilityStatus)

  const [response, setResponse] = useState([]);
  const [PagResponse, setPagResponse] = useState([]);

  const [aux, setAux] = useState([]);
  const [loader, setLoader] = useState(true);

  const fetchData = async (params = { rows: 25, page: 0 }) => {
    try {
      setLoader(true) // loader does not resets when we recall fetch data
      let fetchedData;
      if (route !== "") fetchedData = await apiFunction(route);
      else fetchedData = await apiFunction(params);

      if(hasPaginator) setPagResponse(fetchedData)

      handleData(
        fetchedData[object],
        setResponse,
        filterArray,
        setLoader,
        setAux
      );
    } catch (err) {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    filterResponse(aux, filterArray, setResponse);

    if(object == 'availableDetail')
    filterResponseMoreData(aux, filterArray, filterAvailabilityStatus, 'status', setResponse);
  }, [filterArray]);

  useEffect(() => {
    if(object == 'availableDetail')
    filterResponseMoreData(aux, filterArray, filterAvailabilityStatus, 'status', setResponse);
  }, [filterAvailabilityStatus]);

  return { response, loader, fetchData, setResponse, aux, PagResponse,setLoader };
};

export default useFilterResponse;
