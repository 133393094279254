import { useEffect, useState } from "react";
import useFilterResponse from "../../hooks/useFilterResponse";
import { CircularProgress } from "@mui/material";
import NotesDetails from "./views/notesDetails";
import { getNotesDetail } from "../../api/api";
import { useSelector } from "react-redux";
import FilterButton from "../../components/filterButton";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function Notes() {
  let navigate = useNavigate();
  const status = useSelector((state) => state.sidebar.value);
  const handleRedirectMaintenanceButton = () => navigate("/overview");
  const [rowValue, setRowValue] = useState("");

  const {
    response: notesDetailsData,
    loader: notesDetailsLoader,
    fetchData: reftechNotesDetails,
  } = useFilterResponse(getNotesDetail, "notesDetails");

  useEffect(() => {
    reftechNotesDetails();
  }, [localStorage.getItem("@properties-filter")]);

  const groupingInputHandler = (val) => {
    setRowValue(val.target.value);
  };

  return (
    <div id="main">
      <div className={`content ${status}`}>
        <div className={`child ${status}`}>
          <FilterButton hasTitle={true} title="Notes Details" />
          <div className="line"></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <button onClick={handleRedirectMaintenanceButton}>
              {"<"} BACK
            </button>
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <InputLabel>Group By</InputLabel>
              <Select value={rowValue} onChange={groupingInputHandler}>
                {rowValue != '' && (<MenuItem value=""> <em>None</em> </MenuItem>)}
                <MenuItem value={"user"}>Note From</MenuItem>
                <MenuItem value={"table"}>Table</MenuItem>
                <MenuItem value={"area"}>Area</MenuItem>
                <MenuItem value={"property"}>Property</MenuItem>
              </Select>
            </FormControl>
          </div>
          {notesDetailsLoader ? (
            <CircularProgress />
          ) : (
            <>
            {notesDetailsData.length > 0? <NotesDetails data={notesDetailsData} rowGroupingData={rowValue} /> : <h1 style={{textAlign:'center',height:'66%'}}><br></br>There are no active notes at this time.</h1>}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
