import React from 'react';
import { Typography, Container } from '@mui/material';

const NoDataAvailable = () => {
  return (
    <Container 
      style={{ 
        textAlign: "center", 
        marginTop: "20px", 
        padding: "30px", 
        border: "1px solid #ccc", 
        borderRadius: "12px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
      }}
    >
      <Typography 
        variant="h5" 
        color="error" 
        style={{ 
          fontWeight: '600', 
          marginBottom: "15px" 
        }}
      >
        No data available
      </Typography>
      <Typography 
        variant="body1" 
        style={{ 
          marginTop: "10px", 
          fontSize: "1rem", 
          color: "#555" 
        }}
      >
        Please contact your system administrator
      </Typography>
    </Container>
  );
};

export default NoDataAvailable;
