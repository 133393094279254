import React, { useState, useRef, useEffect } from "react";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import ExportToolbar from "../../../../components/exportToolBar";
import useTableHeight from "../../../../hooks/useTableHeight";

const NotesDetails = ({ data, rowGroupingData }) => {
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  const vwToPx = (vw) => (vw * width) / 100;

  const columnNames = [
    { name: "Note", width: vwToPx(25) },
    { name: "Note From", type: "singleSelect", width: vwToPx(10) },
    { name: "Date", type: "date", width: vwToPx(14) },
    { name: "Value", width: vwToPx(7) },
    { name: "Column", type: "singleSelect", width: vwToPx(10)},
    { name: "Table", type: "singleSelect", width: vwToPx(8) },
    { name: "Area", type: "singleSelect", width: vwToPx(8) },
    { name: "Property", type: "singleSelect", width: vwToPx(18) },
  ];

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  const height = useTableHeight(data);
  let { columenGen } = useMUIFilter(data, columnNames);
  const apiRef = useGridApiRef();
  let columns = columenGen();

  return (
    <div
      style={{ height: 'calc(100vh - 330px)' }}
      ref={elementRef}
    >
      <DataGridPremium
        sx={{
          "&&& .MuiDataGrid-cellContent": {
            overflowWrap: "break-word",
            whiteSpace: "normal",
          },
        }}
        className="grid-style"
        slots={{
          toolbar: ExportToolbar,
        }}
        getRowHeight={()=> 'auto'}
        apiRef={apiRef}
        rows={data}
        columns={columns}
        disableColumnMenu={true}
        hideFooter
        disableVirtualization
        disableColumnSelector
        rowGroupingModel={[rowGroupingData]}
      />
    </div>
  );
};

export default NotesDetails;
