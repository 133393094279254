import React, {useState, useEffect, useRef} from "react";
import Note from "../../../../components/note";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getNotes } from "../../../../api/api";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";
import CellWithTracer from "../../../../components/cellTracer/CellTracer";

const RenewalsMoreTable = ({ data }) => {

  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  const vwToPx = (vw) => (vw * width) / 100;

  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "RenewalsMoreDetails"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
    handleCheckFlagforUnits
  } = useNoteVisibility(listNotes, loader);

    const columnNames = [
      {name: "Property", type:'singleSelect', width: vwToPx(19)},
      {name: "Unit", type:'singleSelect', width: vwToPx(6)},
      {name: "Renewal Status", type:'singleSelect', width: vwToPx(10)},
      {name: 'Current Lease End', type:'date', width: vwToPx(8)},
      {name: 'Renewal Sent By', type:'date', width: vwToPx(8)},
      {name: 'Renewal Due Back By', type:'date', width: vwToPx(8)},
      {name: 'Offer Sent', type:'date', width: vwToPx(8)},
      {name: 'Current Rent', type:'number', width: vwToPx(8), valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
      {name: 'Renewal Rent', type:'number', width: vwToPx(8), valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
      {name: 'Renewal Increase $', type:'number', width: vwToPx(8), valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
      {name: 'Renewal Increase %', type:'number', width: vwToPx(8), valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toFixed(0).toLocaleString()}%`;}},
  ];

  let { columenGen, _, notesColumnNameGen } = useMUIFilter(data, columnNames);

  let columns = columenGen();

let renewalStatusClass = function renewalStatusFunction(item){
  if(item?.row.renewalStatus !== "" && item?.row.renewalStatus == "Renewal Not Sent" && new Date(item?.row.renewalSentBy) < new Date()){
// return `Red ${handleCheckFlag(item?.propertyID, 2)}`
return `Red ${handleCheckFlagforUnits(item?.row.propertyID, 2, item?.row.bldg)}`
  }else{
    // return handleCheckFlag(item?.propertyID, 2)
    return handleCheckFlagforUnits(item?.row.propertyID, 2, item?.row.bldg)
  }
}

let renewalDueBackBy = function renewalDueBackByFunction(item){
  if(item?.row.renewalDueBackBy !== "" && new Date(item?.row.renewalDueBackBy) < new Date()){
// return `Red ${handleCheckFlag(item?.propertyID, 5)}`
return `Red ${handleCheckFlagforUnits(item?.row.propertyID, 5, item?.row.bldg)}`
  }else{
    // return handleCheckFlag(item?.propertyID, 5)
    return handleCheckFlagforUnits(item?.row.propertyID, 5, item?.row.bldg)
  }
}

  return (
    <div className="container-table" style={{ height: "auto" }} ref={elementRef}>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x-350,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notesColumnNameGen()}
            table="RenewalsMoreDetails"
            tableFullName="Renewals Details"
            tab="Occupancy"
          />
        </div>
      )}
            <DataGridPremium
             sx={{
              '&&& .updated': {
                transition: (theme) =>
                  theme.transitions.create(['background-color', 'outline'], {
                    duration: theme.transitions.duration.standard,
                  }),
              },
              '&&& .updating': {
                backgroundColor: 'rgb(92 199 68 / 20%)',
                outline: '1px solid rgb(92 199 68 / 35%)',
                outlineOffset: '-1px',
                transition: 'none',
              },
            }}
        className="grid-style"
        slots={{
          toolbar: ExportToolbar, cell:CellWithTracer,
        }}
        pinnedColumns={{left: ['name', 'bldg']}}
        rows={data}
        columns={columns}
        disableColumnMenu={true}
        hideFooter
        disableVirtualization
        disableColumnSelector
        getCellClassName={(params) => {
          if(params.field === "renewalStatus"){
return renewalStatusClass(params)
          }else if(params.field === "renewalDueBackBy"){
           return renewalDueBackBy(params)
          }else if(params.field === 'renewalIncreaseD'){
            return params?.row.renewalIncreaseD > 0 ? `${handleCheckFlagforUnits(params?.row.propertyID, params.colDef.colNum, params?.row.bldg)}` : `Red ${handleCheckFlagforUnits(params?.row.propertyID, params.colDef.colNum, params?.row.bldg)}`
          }else if(params.field === 'renewalIncreaseP'){
            return params?.row.renewalIncreaseP > 0 ? `${handleCheckFlagforUnits(params?.row.propertyID, params.colDef.colNum, params?.row.bldg)}` : `Red ${handleCheckFlagforUnits(params?.row.propertyID, params.colDef.colNum, params?.row.bldg)}`
          }else{
            return handleCheckFlagforUnits(params?.row.propertyID, params.colDef.colNum, params?.row.bldg)
          }
        }}
        onCellClick={(cell, event) => {
          handleNote(
            cell.row.propertyID,
            cell.colDef.colNum,
            cell.formattedValue,
            cell.row.bldg
          )(event);
        }}
      />
    </div>
  );
};

export default RenewalsMoreTable;

//Legacy Code
// import React from "react";
// import Note from "../../../../components/note";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import { getNotes } from "../../../../api/api";
// import { percentage } from "../../../../functions/decimals";

// const RenewalsMoreTable = ({ data }) => {
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "RenewalsMoreDetails"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//     handleCheckFlagforUnits
//   } = useNoteVisibility(listNotes, loader);
//   // const columnNames = [
//   //   "Property",
//   //   "BLDG Unit ",
//   //   "Current Lease Status ",
//   //   "Renewal Status ",
//   //   "Due Back By ",
//   //   "Current Lease End ",
//   //   "Offer Created ",
//   //   "Offer Accepted ",
//   //   "Lease Generated ",
//   //   "Resident Lease Signed ",
//   // ];

//     const columnNames = [
//       "Property",
//       "Unit",
//       "Renewal Status",
//       'Current Lease End',
//       'Renewal Sent By',
//       'Renewal Due Back By',
//       'Offer Sent',
//       'Current Rent',
//       'Renewal Rent',
//       'Renewal Increase $',
//       'Renewal Increase %'
//   ];

// let renewalStatusClass = function renewalStatusFunction(item){
//   if(item?.renewalStatus !== "" && item?.renewalStatus == "Renewal Not Sent" && new Date(item?.renewalSentBy) < new Date()){
// // return `Red ${handleCheckFlag(item?.propertyID, 2)}`
// return `Red ${handleCheckFlagforUnits(item?.propertyID, 2, item?.bldg)}`
//   }else{
//     // return handleCheckFlag(item?.propertyID, 2)
//     return handleCheckFlagforUnits(item?.propertyID, 2, item?.bldg)
//   }
// }

// let renewalDueBackBy = function renewalDueBackByFunction(item){
//   if(item?.renewalDueBackBy !== "" && new Date(item?.renewalDueBackBy) < new Date()){
// // return `Red ${handleCheckFlag(item?.propertyID, 5)}`
// return `Red ${handleCheckFlagforUnits(item?.propertyID, 5, item?.bldg)}`
//   }else{
//     // return handleCheckFlag(item?.propertyID, 5)
//     return handleCheckFlagforUnits(item?.propertyID, 5, item?.bldg)
//   }
// }

//   return (
//     <div className="container-table" style={{ height: "auto" }}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note
//             notes={notes}
//             columnNames={columnNames}
//             table="RenewalsMoreDetails"
//           />
//         </div>
//       )}
//       <div className="wrapper">
//         <table>
//           <thead>
//             <tr>
//               {columnNames.map((column) => {
//                 return <th key={column}>{column}</th>;
//               })}
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td
//                   // onClick={handleNote(item?.propertyID, 0)}
//                   className={`${handleCheckFlagforUnits(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`}
//                 >
//                   {item?.name}
//                 </td>
//                 <td
//                   // onClick={handleNote(item?.propertyID, 1)}
//                   className={handleCheckFlagforUnits(item?.propertyID, 1)}
//                 >
//                   {item?.bldg}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 2, item?.renewalStatus, item?.bldg)}
//                   // className={handleCheckFlag(item?.propertyID, 2)}
//                   className={renewalStatusClass(item)}
//                 >
//                   {/* {item?.currentLeaseStatus} */}
//                   {item?.renewalStatus}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 3, item?.currentLeaseEnd, item?.bldg)}
//                   className={handleCheckFlagforUnits(item?.propertyID, 3, item?.bldg)}
//                 >
//                   {/* {item?.renewalStatus} */}
//                   {item?.currentLeaseEnd}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 4, item?.renewalSentBy, item?.bldg)}
//                   className={handleCheckFlagforUnits(item?.propertyID, 4, item?.bldg)}
//                 >
//                   {/* {item?.dueBack} */}
//                   {item?.renewalSentBy}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 5, item?.renewalDueBackBy, item?.bldg)}
//                   // className={handleCheckFlag(item?.propertyID, 5)}
//                   className={renewalDueBackBy(item)}
//                 >
//                   {/* {item?.currentLeaseEnd} */}
//                   {item?.renewalDueBackBy}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 6, item?.offerSent, item?.bldg)}
//                   className={handleCheckFlagforUnits(item?.propertyID, 6, item?.bldg)}
//                 >
//                   {/* {item?.offerCreated} */}
//                   {item?.offerSent}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 7, item?.currentRent.toLocaleString(), item?.bldg)}
//                   className={handleCheckFlagforUnits(item?.propertyID, 7, item?.bldg)}
//                   style={{textAlign:'right'}}
//                 >
//                   {/* {item?.offerAccepted} */}
//                   {`$${item?.currentRent.toLocaleString()}`}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 8, item?.renewalRent ? `$${item?.renewalRent.toLocaleString()}` : '', item?.bldg)}
//                   className={handleCheckFlagforUnits(item?.propertyID, 8, item?.bldg)}
//                   style={{textAlign:'right'}}
//                 >
//                   {/* {item?.leaseGenerated} */}
//                   {item?.renewalRent ? `$${item?.renewalRent.toLocaleString()}` : ''}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 9, item?.renewalIncreaseD, item?.bldg)}
//                   className={ item?.renewalIncreaseP > 0 ? `${handleCheckFlagforUnits(item?.propertyID, 9, item?.bldg)}` : `Red ${handleCheckFlagforUnits(item?.propertyID, 9, item?.bldg)}`}
//                 >
//                   {/* {item?.residentLease} */}
//                   {item?.renewalIncreaseD}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 10, item?.renewalIncreaseP, item?.bldg)}
//                   className={ item?.renewalIncreaseP > 0 ? `${handleCheckFlagforUnits(item?.propertyID, 9, item?.bldg)}` : `Red ${handleCheckFlagforUnits(item?.propertyID, 10, item?.bldg)}`}
//                 >
//                   {/* {item?.residentLease} */}
//                   {`${percentage(item?.renewalIncreaseP)}%`}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default RenewalsMoreTable;

