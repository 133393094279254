import React from "react";
import Sidebar from "./components/sidebar/sidebar";
import { Route, Routes, Navigate } from "react-router-dom";
import "./styles.scss";
import Overview from "./views/overview/overview";
import Leasing from "./views/leasing/leasing";
import Occupancy from "./views/occupancy/occupancy";
import Maintenance from "./views/maintenance/maintenance";
import Collections from "./views/collections/collections";
import Budget from "./views/budget/budget";
import Admin from "./views/admin/admin";
import Settings from "./views/settings/settings";
import Help from "./views/help/help";
import "./fonts/Guminert Regular.ttf";
import Filter from "./components/filter/filterAux";
import Thresholds from "./views/admin/thresholds";
import CreateUser from "./views/admin/thresholds/components/newUser";
import RenewalsMore from "./views/occupancy/views/renewalsMore";
import Availability from "./views/leasing/views/availability";
import LeadConversion from "./views/leasing/views/leadConversion";
import MaintenanceDetail from "./views/maintenance-detail";
import CurrentDM from "./views/collections/view";
import CreateGroup from "./views/admin/thresholds/components/newGroup";
import EditUser from "./views/admin/thresholds/components/editUser";
import { EditGroup } from "./views/admin/thresholds/components/editGroup";
import NewNote from "./components/newNote";
import ShowNotes from "./components/showNotes";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BudgetDetails } from "./views/budget/view/budgetDetails";
import Notes from "./views/notes/Notes";

const Dashboard = () => {
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const reset = useSelector((state)=> state.filter.resetResponses);
  const navigate = useNavigate();

  const validateAdminAccess = () => {
    console.log('version. 10102023 1230')
    if (!isAdmin) navigate("/overview");
  };

  return (
    <div>
      <Filter />
      <NewNote />
      <ShowNotes />
      <div id="layout" key={reset}>
        <Sidebar />
        <Routes>
          <Route path="/" element={<Navigate to="/overview" />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/leasing" element={<Leasing />} />
          <Route path="/occupancy" element={<Occupancy />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/budget" element={<Budget />} />
          <Route
            path="/admin"
            element={<Admin onEnter={validateAdminAccess} />}
          />
          <Route path="/settings" element={<Settings />} />
          <Route path="/help" element={<Help />} />
          <Route path="/thresholds" element={<Thresholds />} />
          <Route path="*" element={<Navigate to="/overview" />} />
          <Route path="/createUser" element={<CreateUser />} />
          <Route path="/editUser/:userId" element={<EditUser />} />
          <Route path="/createGroup" element={<CreateGroup />} />
          <Route path="/editGroup/:groupId" element={<EditGroup />} />
          <Route path="/renewals" element={<RenewalsMore />} />
          <Route path="/availability" element={<Availability />} />
          <Route path="/maintenance-detail" element={<MaintenanceDetail />} />
          <Route path="/lead-conversion" element={<LeadConversion />} />
          <Route path="/collections-details" element={<CurrentDM />} />
          <Route path="/budget-details" element={<BudgetDetails/>}/>
          <Route path="/notesDetails" element={<Notes/>}/>
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
