import React, { useEffect } from "react";
import "../../styles.scss";
import "./collections.scss";
import { useSelector } from "react-redux";
import AgedTable from "./tables/agedD";
import CurrentDT from "./tables/currentD";
import { useNavigate } from "react-router-dom";
import {
  getAgedDelinquency,
  getCurrentDelinquency,
  getTrendingDelinquency,
} from "../../api/api";
import CircularProgress from "@mui/material/CircularProgress";
import Tabs from "./tab";
import FilterButton from "../../components/filterButton";
import useFilterResponse from "../../hooks/useFilterResponse";
import NoDataAvailable from "../../components/error/NoDataAvailable";

const Collections = () => {
  const status = useSelector((state) => state.sidebar.value);

  const navigate = useNavigate();

  const {
    response: trendingData,
    loader: trendingLoader,
    fetchData: refetchDataTD,
  } = useFilterResponse(getTrendingDelinquency, "trendingDelinquency");
  const {
    response: agedData,
    loader: agedLoader,
    fetchData: refetchDataAD,
  } = useFilterResponse(getAgedDelinquency, "agedDelinquency");
  const {
    response: currentData,
    loader: currentLoader,
    fetchData: refetchDataCD,
  } = useFilterResponse(getCurrentDelinquency, "currentDelinquency");

  useEffect(() => {
    refetchDataTD();
    refetchDataAD();
    refetchDataCD();
  }, [localStorage.getItem("@properties-filter")]);

  const handleRedirectCDMButton = () => navigate("/collections-details");

  return (
    <>
      <div id="main">
        <FilterButton />
        <div className={`content ${status}`}>
          <div className="collections-container">
            <div className={`child ${status}`}>
              <div className="hasbutton">
                <h2>Current Delinquencies</h2>
                <button onClick={handleRedirectCDMButton}>MORE DETAILS</button>
              </div>
              <div className="line"></div>
              {currentLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : currentData.length > 0 ? (
                <CurrentDT data={currentData} />
              ) : (
                <NoDataAvailable />
              )}
            </div>
            <div className={`child ${status}`}>
              <h2>Trending Delinquencies</h2>
              <div className="line"></div>
              {trendingLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : trendingData.length > 0 ? (
                <Tabs data={trendingData} />
              ) : (
                <NoDataAvailable />
              )}
            </div>
            <div className={`child ${status}`}>
              <h2>Aged Delinquency</h2>
              <div className="line"></div>
              {agedLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : agedData.length > 0 ? (
                <AgedTable data={agedData} />
              ) : (
                <NoDataAvailable />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collections;
