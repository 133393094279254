import React, { useState, useEffect } from "react";
import "../../styles.scss";
import "./occupancy.scss";
import { useSelector } from "react-redux";
import Projected from "./tables/projected";
import Renewals from "./tables/renewals";
import Schedules from "./tables/schedules";
import { useNavigate } from "react-router-dom";
import {
  generateRenewals,
  getCurrentOccupancy,
  generateSchedulesByDate,
} from "../../api/api";
import CircularProgress from "@mui/material/CircularProgress";
import FilterButton from "../../components/filterButton";
import useFilterResponse from "../../hooks/useFilterResponse";
import NoDataAvailable from '../../../src/components/error/NoDataAvailable'

const Occupancy = () => {
  const navigate = useNavigate();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const status = useSelector((state) => state.sidebar.value);

  const [selectedValue, setSelectedValue] = useState("");
  const [months, setMonths] = useState([]);

  const {response: renewalsData, loader: loaderRenewals, fetchData: refetchDataGR} = useFilterResponse(generateRenewals,'renewals')
  const {response: currentO, loader: loaderOccupancy, fetchData: refetchDataCO} = useFilterResponse(getCurrentOccupancy,'currentOccupancy')
  const {response: scheduleData, loader: loaderSchedule, fetchData: refetchDataSBD} = useFilterResponse(generateSchedulesByDate,'scheduleMovInMovOut')

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    // fetchData({date: event.target.value})
    refetchDataSBD({date: event.target.value})
  };

  useEffect(() => {
    refetchDataGR()
    refetchDataCO()
    refetchDataSBD()
    }, [localStorage.getItem('@properties-filter')]);

  useEffect(() => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const generateMonthLabel = (monthIndex, year) => {
      const month = monthNames[monthIndex % 12];
      return `${month} ${year}`;
    };

    const generateMonths = () => {
      const months = [];
      for (let i = 0; i < 6; i++) {
        const month = (currentMonth + i) % 12;
        const year = currentYear + Math.floor((currentMonth + i) / 12);
        const label = generateMonthLabel(month, year);
        const formattedMonth = (month + 1).toString().padStart(2, "0"); // Add leading zero if month is 1-9
        const value = `${formattedMonth}/${year}`;
        if (i === 0) {
          setSelectedValue(value);
        }
        months.push({ label, value });
      }
      return months;
    };

    const months = generateMonths();
    setMonths(months);
  }, []);

  const handleRedirectRenewalsButton = () => navigate("/renewals");

  return (
    <>
      <div id="main">
        <FilterButton/>
        <div className={`content ${status}`}>
          <div className="occupancy-container">
            <div className={`child ${status}`}>
              <h2>Projected Occupancy</h2>
              <div className="line"></div>
              {loaderOccupancy ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : currentO.length > 0 ? (
                <Projected data={currentO} />
              ) : (
                <NoDataAvailable/>
              )}
            </div>
            <div className={`child ${status}`}>
              <div className="hasbutton">
                <h2>Scheduled Move Ins & Move Outs</h2>
                <select style={{cursor:'pointer'}} value={selectedValue} onChange={handleChange}>
                  <option value="0">No Timeframe</option>
                  {months?.map((m, index) => {
                    return (
                      <option key={index} value={m?.value}>
                        {m?.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="line"></div>
              {loaderSchedule ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : scheduleData.length > 0 ? (
                <Schedules data={scheduleData} />
              ) : (
                <NoDataAvailable/>
              )}
            </div>
            <div className={`child ${status}`}>
              <div className="hasbutton">
                <h2>Renewals</h2>
                <button onClick={handleRedirectRenewalsButton}>
                  MORE DETAILS
                </button>
              </div>
              <div className="line"></div>
              {loaderRenewals ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : renewalsData.length > 0 ? (
                <Renewals data={renewalsData} />
              ) : (
                <NoDataAvailable/>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Occupancy;
