import React, { useState, useEffect } from "react";
import "../../styles.scss";
import "./maintenance.scss";
import { useSelector } from "react-redux";
import WorkOrders from "./tables/workOrders";
import NewWorkOrders from "./tables/newWorkOrders";
import LineChart from "./chart";
import { useNavigate } from "react-router-dom";
import TurnoversTable from "./tables/turnoversTable";
import chartActive from "../../assets/Chart Icon On.svg";
import chartInactive from "../../assets/Chart Icon Off.svg";
import listActive from "../../assets/List Icon On.svg";
import listInactive from "../../assets/List Icon Off.svg";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getCurrentsWorkOrders,
  getTrendWorkOrders,
  getTurnOver,
} from "../../api/api";
import FilterButton from "../../components/filterButton";
import useFilterResponse from "../../hooks/useFilterResponse";
import NoDataAvailable from "../../components/error/NoDataAvailable";

const Maintenance = () => {
  const navigate = useNavigate();
  const status = useSelector((state) => state.sidebar.value);
  const handleRedirectDetailsButton = () => navigate("/maintenance-detail");

  const {
    response: workOrderData,
    loader: workOrderLoader,
    fetchData: refetchDataCWO,
  } = useFilterResponse(getCurrentsWorkOrders, "currentWorkOrders");
  const {
    response: newWorkOrderData,
    loader: newWorkOrderLoader,
    fetchData: refetchDataTWO,
  } = useFilterResponse(getTrendWorkOrders, "newWorkOrderTrend");
  const {
    response: turnOversData,
    loader: turnOverLoader,
    fetchData: refetchDataTO,
  } = useFilterResponse(getTurnOver, "turnOver");

  useEffect(() => {
    refetchDataCWO();
    refetchDataTWO();
    refetchDataTO();
  }, [localStorage.getItem("@properties-filter")]);

  const [toggleChart, setToggleChart] = useState(false);

  const toggles = () => {
    if (toggleChart) {
      return (
        <div className="toggles-chart">
          <img
            alt="chartInactive"
            onClick={() => setToggleChart(false)}
            src={chartInactive}
          />
          <img
            alt="listActive"
            onClick={() => setToggleChart(true)}
            src={listActive}
          />
        </div>
      );
    } else {
      return (
        <div className="toggles-chart">
          <img
            alt="chartActive"
            onClick={() => setToggleChart(false)}
            src={chartActive}
          />
          <img
            alt="listInactive"
            onClick={() => setToggleChart(true)}
            src={listInactive}
          />
        </div>
      );
    }
  };

  const toggleCharts = () => {
    if (toggleChart) {
      return <NewWorkOrders data={newWorkOrderData} />;
    } else {
      return <LineChart info={newWorkOrderData}></LineChart>;
    }
  };

  return (
    <>
      <div id="main">
        <FilterButton />
        <div className={`content ${status}`}>
          <div className="maintenance-container">
            <div className={`child ${status}`}>
              <div className="hasbutton">
                <h2>Work Orders</h2>
                <button onClick={handleRedirectDetailsButton}>
                  MORE DETAILS
                </button>
              </div>
              <div className="line"></div>
              {workOrderLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : workOrderData.length > 0 ? (
                <WorkOrders data={workOrderData} />
              ) : (
                <NoDataAvailable />
              )}
            </div>
            <div className={`child ${status}`} id="NewWorkOrderTrend">
              <div className="hasbutton">
                <h2>New Work Order Trend</h2>
                {toggles()}
              </div>
              <div className="line"></div>
              {newWorkOrderLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : newWorkOrderData.length > 0 ? (
                toggleCharts()
              ) : (
                <NoDataAvailable />
              )}
            </div>
            <div className={`child ${status}`}>
              <h2>Turnovers</h2>
              <div className="line"></div>
              {turnOverLoader ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : turnOversData.length > 0 ? (
                <TurnoversTable data={turnOversData} />
              ) : (
                <NoDataAvailable />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maintenance;
