import React,{useEffect, useRef, useState} from "react";
import useTableHeight from "../../../../hooks/useTableHeight";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getNotes } from "../../../../api/api";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import Note from "../../../../components/note";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";

const WorkOrders = ({ data }) => {

  const [pageWidth, setPageWidth] = useState(null)
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  let functionDelaytimeOut

  window.addEventListener('resize', (size) => {
    let currentTarget = size?.currentTarget?.innerWidth
    if (functionDelaytimeOut) {
      clearTimeout(functionDelaytimeOut);
    }
    functionDelaytimeOut = setTimeout(() => {
      setPageWidth(currentTarget);
    }, 800);
  });

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
    return () => {
      if (functionDelaytimeOut) {
        clearTimeout(functionDelaytimeOut);
      }
    };
  }, [pageWidth]);

  const vwToPx = (vw) => (vw * width) / 100;

  const columnNames = [
    {name:"Property", type:'singleSelect', width: vwToPx(19)},
    {name:"Current Work Order", type:'number', width: vwToPx(13)},
    {name:"Oldest Work Order (Days)", type:'number', width: vwToPx(15)},
    {name:"Open", type:'number', width: vwToPx(13)},
    {name:"Open > 7 Days", type:'number', width: vwToPx(13)},
    {name:"Avg. Days Open", type:'number', width: vwToPx(13)},
    {name:"Oldest Open Work Order", type:'number', width: vwToPx(13)},
  ];

  const thresHoldValues = {
    oldest:{
      thresHoldValue:'OldestWorkOrderDays'
  }, 
  openMore:{
    thresHoldValue:'OpenGreaterXDays'
  }, 
  avg:{
    thresHoldValue:'AvgDaysOpen'
  }, 
  oldestOpen:{
    thresHoldValue:'OldestOpenWorkOrder'
  }
}

  let { columenGen, thresHolds, notesColumnNameGen } = useMUIFilter(
    data,
    columnNames,
    "Work Orders"
  );

  let columns = columenGen();

  const height = useTableHeight(data);
  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "WorkOrders"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
  } = useNoteVisibility(listNotes, loader);
  const {getThresholdData} = useThreshold({view:'maintenance', table: 'WorkOrders', loader})

  return (
    <div className="container-table" style={{ height: height }} ref={elementRef}>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x-350,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notesColumnNameGen()}
            table="WorkOrders"
            tableFullName="Work Orders"
            tab="Maintenance"
          />
        </div>
      )}
<DataGridPremium
  className="grid-style"
  rows={data}
  columns={columns}
  onCellClick={(cell,event)=>{handleNote(cell.row.propertyID, cell.colDef.colNum, cell.formattedValue)(event)}}
  getCellClassName={(params) => {
    return `${thresHolds(params, getThresholdData,thresHoldValues)} ${handleCheckFlag(params.row.propertyID, params.colDef.colNum)}`
  }}
  slots={{ toolbar: ExportToolbar}}
  pinnedColumns={{left: ['name']}}
  disableVirtualization
  hideFooter
  disableColumnSelector
  disableColumnMenu={true}
  initialState={{
    aggregation: {
      model: {
        current: 'sum',
        oldest: 'max',
        open:'sum',
        openMore:'sum',
        avg:'avg',
        oldestOpen:"max"
      },
    },
  }}
/>
    </div>
  );
};

export default WorkOrders;

//Lagecy code
// import React from "react";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import { getNotes } from "../../../../api/api";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import Note from "../../../../components/note";
// import { useThreshold } from "../../../../hooks/useThreshold";

// const WorkOrders = ({ data }) => {
//   const columnNames = [
//     "Property",
//     "Current Work Order",
//     "Oldest Work Order (Days)",
//     "Open",
//     "Open > 7 Days",
//     "Avg. Days Open",
//     "Oldest Open Work Order",
//   ];

//   const height = useTableHeight(data);
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "WorkOrders"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//   } = useNoteVisibility(listNotes, loader);
//   const {getThresholdData} = useThreshold({view:'maintenance', table: 'WorkOrders', loader})
 
//   const totalcurrent = data.reduce((acc, curr) => acc + curr?.current, 0);
//   // const totaloldest = data.reduce((acc, curr) => acc + curr?.oldest, 0);
//   const totaloldest = data.reduce((max,curr) => curr?.oldest > max ? curr.oldest : max, 0);
//   const totalopen = data.reduce((acc, curr) => acc + curr?.open, 0);
//   const totalopenMore = data.reduce((acc, curr) => acc + curr?.openMore, 0);
//   const totalavg = data.reduce((acc, curr) => acc + curr?.avg, 0);
//   // const totaloldestOpen = data.reduce((acc, curr) => acc + curr?.oldestOpen, 0);
//   const totaloldestOpen = data.reduce((max,curr) => curr?.oldestOpen > max ? curr.oldestOpen : max, 0)

//   return (
//     <div className="container-table" style={{ height: height }}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note
//             notes={notes}
//             columnNames={columnNames}
//             table="WorkOrders"
//           />
//         </div>
//       )}
//       <div className="wrapper">
//         <table className="hasColors">
//           <thead>
//             <tr>
//               {columnNames.map((column) => {
//                 return <th key={column}>{column}</th>;
//               })}
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td
//                   onClick={handleNote(item?.propertyID, 0, item?.name, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`}
//                 >
//                   {item?.name}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 1, item?.current, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 1)}
//                 >
//                   {item?.current}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 2, item?.oldest, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 2)}
//                   ${getThresholdData(parseInt(item?.oldest), 'OldestWorkOrderDays')}`}
//                 >
//                   {item?.oldest}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 3, item?.open, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 3)}
//                 >
//                   {item?.open}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 4, item?.openMore, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 4)}
//                   ${getThresholdData(parseInt(item?.openMore), 'OpenGreaterXDays')}`}
//                 >
//                   {item?.openMore}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 5, item?.avg, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 5)}
//                   ${getThresholdData(parseInt(item?.avg), 'AvgDaysOpen')}`}
//                 >
//                   {item?.avg}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 6, item?.oldestOpen, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 6)}
//                   ${getThresholdData(parseInt(item?.oldestOpen), 'OldestOpenWorkOrder')}`}
//                 >
//                   {item?.oldestOpen}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td>{totalcurrent}</td>
//               <td>{totaloldest}</td>
//               <td>{totalopen}</td>
//               <td>{totalopenMore}</td>
//               <td>{parseInt(totalavg/data.length)}</td>
//               <td>{totaloldestOpen}</td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default WorkOrders;

